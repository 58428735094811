
import React, { useState, useEffect, useRef, useCallback , useContext} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {  Modal, Paper, FormControl, InputLabel} from '@mui/material';
import axios from "axios"
import TableRow from '@mui/material/TableRow';
import { Select, MenuItem } from '@mui/material';
import abbreviateNumber from '../utils/abbreviateNumber';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import cloneDeep from 'lodash/cloneDeep';
import TextField from '@mui/material/TextField';
import TocIcon from '@mui/icons-material/Toc';
import PricingPopup from '../account/pricing/PricingPopUp';
import AuthContext from '../context/AuthContext';

import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { BrowserRouter, useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';

import { API } from '../api/index';
import dataTemplate from './CompanyGraphTemplates/dataTemplate';
import optionTemplate from './CompanyGraphTemplates/optionTemplate';
import plugins from "./CompanyGraphTemplates/pluginsTemplate"
import useAxios from '../api/useAxiosPrivate';
import { Bar, Line } from "react-chartjs-2";
import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
  } from "chart.js";
import { round } from 'lodash';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,

  );




const CompanyInfo = () => {

  const axiosPrivate = useAxios();

  const location = useLocation();
  const currentPath = location.pathname;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:1700px)');
  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)
  const [selectedGraph, setSelectedGraph] = useState('');
  const [selectedOption, setSelectedOption] = useState("");
  //function useQuery() {
    //const { search } = useLocation();
    //return React.useMemo(() => new URLSearchParams(search), [search]);
  //}
    //let query = useQuery();
    //var frequency = query.get("frequency");
    //if(frequency === null){
      //frequency = "quarterly"
    //}
    const [frequency, setFrequency] = useState("quarterly"); 
    const {id} = useParams();

    const [premStatus, setPremStatus] = useState(null);
    const [error, setError] = useState("");
    const theme = useTheme();

    const [showGraph, setShowGraph] = useState(false); 
    
    // DATA STORAGE

    const [companyName, setCompanyName] = useState("");
    const [companyStockPrice, setCompanyStockPrice] = useState();
    const [companyMarketCap, setCompanyMarketCap] = useState();

    // GENERAL GRAPH
    const [chartList, setChartList] = useState(["Revenue", "RevSeg", "RevGeo"]);

    const [graphsxAxis, setGraphsxAxis] = useState([]);

    // SPECIFIC GRAPH


    
    
    const refGraphRevOpIn = useRef(null);
    const [isStackedRevOpIn, setIsStackedRevOpIn] = useState(false)
    const [graphLabelsRevOpIn, setGraphLabelsRevOpIn] = useState([]);
    const [graphDataRevOpIn, setGraphDataRevOpIn] = useState([]);
    const [graphRevOpIn, setGraphRevOpIn] = useState();
    const dataTemplateGraphRevOpIn = cloneDeep(dataTemplate);;
    const optionTemplateGraphRevOpIn = cloneDeep(optionTemplate);


    const refGraphRevSeg = useRef(null);
    const [isStackedRevSeg, setIsStackedRevSeg] = useState(true);
    const [graphLabelsRevSeg, setGraphLabelsRevSeg] = useState([]);
    const [graphDataRevSeg, setGraphDataRevSeg] = useState([]);
    const [graphRevSeg, setGraphRevSeg] = useState();
    const dataTemplateGraphRevSeg = cloneDeep(dataTemplate);
    const optionTemplateGraphRevSeg = cloneDeep(optionTemplate);
    

    const refGraphRevGeo = useRef(null);
    const [isStackedRevGeo, setIsStackedRevGeo] = useState(true);
    const [graphLabelsRevGeo, setGraphLabelsRevGeo] = useState([]);
    const [graphDataRevGeo, setGraphDataRevGeo] = useState([]);
    const [graphRevGeo, setGraphRevGeo] = useState();
    const dataTemplateGraphRevGeo = cloneDeep(dataTemplate);;
    const optionTemplateGraphRevGeo = cloneDeep(optionTemplate);


    const refGraphNet = useRef(null); 
    const [graphLabelsNet, setGraphLabelsNet] = useState([]);
    const [graphDataNet, setGraphDataNet] = useState([]);
    const [graphNet, setGraphNet] = useState();
    const dataTemplateGraphNet = cloneDeep(dataTemplate);
    const optionTemplateGraphNet = cloneDeep(optionTemplate);

    
    const refGraphOpEx = useRef(null);
    const [isStackedOpEx, setIsStackedOpEx] = useState(false);
    const [graphLabelsOpEx, setGraphLabelsOpEx] = useState([]);
    const [graphDataOpEx, setGraphDataOpEx] = useState([]);
    const [graphOpEx, setGraphOpEx] = useState();
    const dataTemplateGraphOpEx = cloneDeep(dataTemplate);
    const optionTemplateGraphOpEx = cloneDeep(optionTemplate);


    const refGraphGrowth = useRef(null); 
    const [graphLabelsGrowth, setGraphLabelsGrowth] = useState([]);
    const [graphDataGrowth, setGraphDataGrowth] = useState([]);
    const [graphGrowth, setGraphGrowth] = useState();
    const dataTemplateGraphGrowth = cloneDeep(dataTemplate);
    const optionTemplateGraphGrowth = cloneDeep(optionTemplate);


    const refGraphCashFlow = useRef(null); 
    const [isStackedCashFlow, setIsStackedCashFlow] = useState(false);
    const [graphLabelsCashFlow, setGraphLabelsCashFlow] = useState([]);
    const [graphDataCashFlow, setGraphDataCashFlow] = useState([]);
    const [graphCashFlow, setGraphCashFlow] = useState();
    const dataTemplateGraphCashFlow = cloneDeep(dataTemplate);
    const optionTemplateGraphCashFlow = cloneDeep(optionTemplate);

    const refGraphCash = useRef(null); 
    const [graphLabelsCash, setGraphLabelsCash] = useState([]);
    const [graphDataCash, setGraphDataCash] = useState([]);
    const [graphCash, setGraphCash] = useState();
    const dataTemplateGraphCash = cloneDeep(dataTemplate);
    const optionTemplateGraphCash = cloneDeep(optionTemplate);
    

    const refGraphMargins = useRef(null);
    const [graphLabelsMargins, setGraphLabelsMargins] = useState([]);
    const [graphDataMargins, setGraphDataMargins] = useState([]);
    const [graphMargins, setGraphMargins] = useState();
    const dataTemplateGraphMargins = cloneDeep(dataTemplate);
    const optionTemplateGraphMargins = cloneDeep(optionTemplate);


    const refGraphMarCap = useRef(null);
    const [graphLabelsMarCap, setGraphLabelsMarCap] = useState([]);
    const [graphDataMarCap, setGraphDataMarCap] = useState([]);
    const [graphMarCap, setGraphMarCap] = useState();
    const dataTemplateGraphMarCap = cloneDeep(dataTemplate);
    const optionTemplateGraphMarCap = cloneDeep(optionTemplate);


    const refGraphOutShare = useRef(null);
    const [graphLabelsOutShare, setGraphLabelsOutShare] = useState([]);
    const [graphDataOutShare, setGraphDataOutShare] = useState([]);
    const [graphOutShare, setGraphOutShare] = useState();
    const dataTemplateGraphOutShare = cloneDeep(dataTemplate);
    const optionTemplateGraphOutShare = cloneDeep(optionTemplate);


    const [paidGraph, setPaidGraph] = useState();




    const colors = ['#CF4123', '#598F90', "#906059", "#905980", "#59906F", "#7C906F", "#5252AA", "#4383CC", "#1ABC9C"];
    /*const colors [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)'
  ],*/
    var testData = [[10000000,10000000,10000000],[10000000,10000000,10000000]]

    const testLabels = ["aye","aye2"]
    const testxAxis = ["Q1","Q2","Q3"]






useEffect(() => {
  const fetchPrivateData = async () => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axiosPrivate.get("/api/auth/authorize", config);   
      //console.log(data);
      setPremStatus(data.status);
      //console.log(data.status); // Log the updated premStatus here
    } catch (error) {
      setPremStatus(false);
    }
  };

  fetchPrivateData();
}, []); 







        useEffect(() => {
          const fetchFinancialData = async () => {
            try {
              const config = {
                headers: {
                  "Content-Type": "application/json",
                },
              };
        
              const [quoteResponse, financialResponse, growthResponse, cashFlowResponse, marginsResponse, marCapResponse, revSegResponse, revGeoResponse] = await Promise.all([
                API.post("/api/data/quote", { id }, config, { withCredentials: true }),
                API.post("/api/data/FS", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/growth", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/cashflow", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/margins", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/marcap", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/revseg", { id, frequency }, config, { withCredentials: true }),
                API.post("/api/data/revgeo", { id, frequency }, config, { withCredentials: true }),
              ]);
        
              const quote = quoteResponse.data;
              const incomeStatements = financialResponse.data;
              const growthStatements = growthResponse.data;
              const cashFlowStatements = cashFlowResponse.data;
              const marginsStatements = marginsResponse.data;
              const marCapStatements = marCapResponse.data;
              const revSegStatements = revSegResponse.data;
              const revGeoStatements = revGeoResponse.data;
       

        
              if (quote && Array.isArray(quote) && quote[0] && quote[0].name !== undefined) {
                setCompanyName(quote[0].name);
              } else {
                setCompanyName("Unable to find Ticker");
              }
              setCompanyStockPrice(quote[0].price);
              setCompanyMarketCap(quote[0].marketCap);
              let sliced = 16;
              if (frequency === "annual"){
                sliced = 12
              }
              else if ( frequency === "quarterly"){
                sliced = 16
              }
              const relevantIncomeStatements = incomeStatements.slice(0, sliced);
              const relevantGrowthStatements = growthStatements.slice(0, sliced);
              const relevantCashFlowStatements = cashFlowStatements.slice(0, sliced);
              const relevantMarginsStatements = marginsStatements.slice(0, sliced);
              const relevantMarCapStatements = marCapStatements.slice(0, sliced);
              const relevantRevSegStatements = revSegStatements.slice(0, sliced);
              const relevantRevGeoStatements = revGeoStatements.slice(0, sliced);

              const graphsxAxisTemp = relevantIncomeStatements.map((element) => {
                if (frequency === "annual") {
                  return element.calendarYear;
                } else if (frequency === "quarterly") {
                  return element.calendarYear.substring(2) + " " + element.period;
                }
                return null;
              }).reverse();
        
              const creatorGraphRevOpIn = [
                relevantIncomeStatements.map((element) => element.revenue).reverse(),
                relevantIncomeStatements.map((element) => element.grossProfit).reverse(),
                relevantIncomeStatements.map((element) => element.operatingIncome).reverse(),
              ];
        
              const creatorGraphOpEx = [
                relevantIncomeStatements.map((element) => element.researchAndDevelopmentExpenses).reverse(),
              ];
        
              if (relevantIncomeStatements.some((element) => element.sellingAndMarketingExpenses === 0 && element.generalAndAdministrativeExpenses === 0)) {
                creatorGraphOpEx.push(relevantIncomeStatements.map((element) => element.sellingGeneralAndAdministrativeExpenses).reverse());
                setGraphLabelsOpEx(["R&D", "Selling General & Administrative"]);
              } else {

                creatorGraphOpEx.push(relevantIncomeStatements.map((element) => element.sellingAndMarketingExpenses).reverse());
                creatorGraphOpEx.push(relevantIncomeStatements.map((element) => element.generalAndAdministrativeExpenses).reverse());
                setGraphLabelsOpEx(["R&D", "Marketing", "General & Administrative"]);
              }
            
              const creatorGraphNet = [
                relevantIncomeStatements.map((element) => element.netIncome).reverse(),
              ];
        
              const revenueYbY = relevantGrowthStatements.map((element) => (element.growthRevenue)).reverse();
              const opExYbY = relevantGrowthStatements.map((element) => (element.growthOperatingExpenses)).reverse();

              const creatorGraphCashFlow = [
                relevantCashFlowStatements.map((element) => element.operatingCashFlow).reverse(),
                relevantCashFlowStatements.map((element) => element.capitalExpenditure).reverse(),
                relevantCashFlowStatements.map((element) => element.freeCashFlow).reverse(),
              ];


              const creatorGraphCash = [
                relevantCashFlowStatements.map((element) => element.cashAtEndOfPeriod).reverse(),
              ];


              const creatorGraphMargins = [
                relevantMarginsStatements.map((element) => element.grossProfitMargin).reverse(),
                relevantMarginsStatements.map((element) => element.operatingProfitMargin).reverse(),
                relevantMarginsStatements.map((element) => element.netProfitMargin).reverse(),
              ];


              const creatorGraphMarCap = [
                relevantMarCapStatements.map((element) => element.marketCapitalization).reverse(),
              ];


              const creatorGraphOutShare = [
                relevantMarCapStatements.map((element) => (element.marketCapitalization/element.stockPrice)).reverse(),
              ];


              const creatorGraphRevSegTemp = relevantRevSegStatements[0]
              ? Object.keys(relevantRevSegStatements[0]).map(key => {
                  return relevantRevSegStatements.map(element => element[key]).reverse();
                })
              : [];
                    const graphsXAxisTempLength = graphsxAxisTemp.length; // Assuming graphsXAxisTemp is the reference array

                    const creatorGraphRevSeg = creatorGraphRevSegTemp.map(subArray => {
                      const paddingLength = graphsXAxisTempLength - subArray.length;
                      if (paddingLength > 0) {
                        const padding = new Array(paddingLength).fill(undefined);
                        return [...padding, ...subArray];
                      }
                      return subArray;
                    })
              if (relevantRevSegStatements.length > 0) {
                // Extract element names from the first data point
                const firstDataPoint = relevantRevSegStatements[0];
                const elementNames = Object.keys(firstDataPoint);
                //console.log(elementNames)
                // Update the state with element names
                setGraphLabelsRevSeg(elementNames);
              }


              const creatorGraphRevGeoTemp = relevantRevGeoStatements[0]
              ? Object.keys(relevantRevGeoStatements[0]).map(key => {
                  return relevantRevGeoStatements.map(element => element[key]).reverse();
                })
              : [];

                    const creatorGraphRevGeo = creatorGraphRevGeoTemp.map(subArray => {
                      const paddingLength = graphsXAxisTempLength - subArray.length;
                      if (paddingLength > 0) {
                        const padding = new Array(paddingLength).fill(undefined);
                        return [...padding, ...subArray];
                      }
                      return subArray;
                    })
              if (relevantRevGeoStatements.length > 0) {
                // Extract element names from the first data point
                const firstDataPoint = relevantRevGeoStatements[0];
                const elementNames = Object.keys(firstDataPoint);
                //console.log(elementNames)
                // Update the state with element names
                setGraphLabelsRevGeo(elementNames);
              }


              setGraphsxAxis(graphsxAxisTemp);
              setGraphLabelsRevOpIn(["Revenue", "Gross Profit", "Operating Income"]);
              setGraphDataRevOpIn(creatorGraphRevOpIn);
              setGraphLabelsNet(["Net Income"]);
              setGraphDataNet(creatorGraphNet);
              setGraphDataOpEx(creatorGraphOpEx)
              setGraphLabelsGrowth(["Revenue Y/Y", "OpEx Y/Y"]);
              setGraphDataGrowth([revenueYbY, opExYbY]);
              setGraphLabelsCashFlow(["Operating Cash Flow", "Capital Expenditures", "Free Cash Flow"]);
              setGraphDataCashFlow(creatorGraphCashFlow);
              setGraphLabelsCash(["Cash"]);
              setGraphDataCash(creatorGraphCash);
              setGraphLabelsMargins(["Gross", "Operating", "Profit"]);
              setGraphDataMargins(creatorGraphMargins);
              setGraphLabelsMarCap(["Market Cap"]);
              setGraphDataMarCap(creatorGraphMarCap);
              setGraphLabelsOutShare(["Outstanding Shares"]);
              setGraphDataOutShare(creatorGraphOutShare);
              setGraphDataRevSeg(creatorGraphRevSeg)
              setGraphDataRevGeo(creatorGraphRevGeo)
              
        
            } catch (error) {
              setError(error);
            }
          };
        
          fetchFinancialData();
        }, [frequency]);


        
    const stackedGraphRevOpIn = () => {
      if(isStackedRevOpIn === false) {
        setIsStackedRevOpIn(true)
        optionTemplateGraphRevOpIn.scales.y.stacked =  true
        optionTemplateGraphRevOpIn.scales.x.stacked =  true
      }
      else if( isStackedRevOpIn === true){
        setIsStackedRevOpIn(false)
        optionTemplateGraphRevOpIn.scales.y.stacked =  false
        optionTemplateGraphRevOpIn.scales.x.stacked =  false
      }
    };


    const stackedGraphOpEx = () => {
      if(isStackedOpEx === false) {
        setIsStackedOpEx(true)
        optionTemplateGraphOpEx.scales.y.stacked =  true
        optionTemplateGraphOpEx.scales.x.stacked =  true
      }
      else if( isStackedOpEx === true){
        setIsStackedOpEx(false)
        optionTemplateGraphOpEx.scales.y.stacked =  false
        optionTemplateGraphOpEx.scales.x.stacked =  false
      }
    };

    const stackedGraphCashFlow = () => {
      if(isStackedCashFlow === false) {
        setIsStackedCashFlow(true)
        optionTemplateGraphCashFlow.scales.y.stacked =  true
        optionTemplateGraphCashFlow.scales.x.stacked =  true
      }
      else if( isStackedCashFlow === true){
        setIsStackedCashFlow(false)
        optionTemplateGraphCashFlow.scales.y.stacked =  false
        optionTemplateGraphCashFlow.scales.x.stacked =  false
      }
    };


    const stackedGraphRevSeg = () => {
      if(isStackedRevSeg === false) {
        setIsStackedRevSeg(true)
        optionTemplateGraphRevSeg.scales.y.stacked =  true
        optionTemplateGraphRevSeg.scales.x.stacked =  true
      }
      else if( isStackedRevSeg === true){
        setIsStackedRevSeg(false)
        optionTemplateGraphRevSeg.scales.y.stacked =  false
        optionTemplateGraphRevSeg.scales.x.stacked =  false
      }
    };


    
    const stackedGraphRevGeo = () => {
      if(isStackedRevGeo === false) {
        setIsStackedRevGeo(true)
        optionTemplateGraphRevGeo.scales.y.stacked =  true
        optionTemplateGraphRevGeo.scales.x.stacked =  true
      }
      else if( isStackedRevGeo === true){
        setIsStackedRevGeo(false)
        optionTemplateGraphRevGeo.scales.y.stacked =  false
        optionTemplateGraphRevGeo.scales.x.stacked =  false
      }
    };


    const createDownloadImageFunction = (refFunction, fileName) => {
      return useCallback(() => {
        const link = document.createElement("a");
        link.download = `${id}_${fileName}.png`;
        link.href = refFunction.current.toBase64Image();
        link.click();
      }, []);
    };
    

    const handleFrequency = (value) => {
      // Remove the selected company from the companies array
      setFrequency(value)
    };
  
    const handleGraphChange = (event) => {
      setSelectedGraph(event.target.value);
      // You can perform actions based on the selected graph here

      if (event.target.value === 'graphRevOpIn') {
        refGraphRevOpIn.current.scrollIntoView({ behavior: 'smooth' });
      } else if (event.target.value === 'graphRevSeg') {
        refGraphRevSeg.current.scrollIntoView({ behavior: 'smooth' });
      } else if (event.target.value === 'graphRevGeo') {
        refGraphRevGeo.current.scrollIntoView({ behavior: 'smooth' });
      }
    };






          
  useEffect(() => {
    dataTemplateGraphRevOpIn.datasets = [];
    dataTemplateGraphRevOpIn.labels = graphsxAxis;                     
    optionTemplateGraphRevOpIn.plugins.title.display =  "true"; 
    optionTemplateGraphRevOpIn.plugins.title.text =  "Revenue & Operating Income";
    optionTemplateGraphRevOpIn.scales.y.stacked =  isStackedRevOpIn
    optionTemplateGraphRevOpIn.scales.x.stacked =  isStackedRevOpIn
    for(let i = 0; i < graphDataRevOpIn.length; i ++){
      dataTemplateGraphRevOpIn.datasets.push(
      {
        label: graphLabelsRevOpIn[i], 
        data: graphDataRevOpIn[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphRevOpIn(<Bar     height={170}  plugins = {plugins} ref ={refGraphRevOpIn} data={dataTemplateGraphRevOpIn}  options={optionTemplateGraphRevOpIn}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataRevOpIn, isStackedRevOpIn]);


  useEffect(() => {
    dataTemplateGraphRevSeg.datasets = [];
    dataTemplateGraphRevSeg.labels = graphsxAxis;                     
    optionTemplateGraphRevSeg.plugins.title.text =  "Revenue Segments";
    optionTemplateGraphRevSeg.scales.y.stacked =  isStackedRevSeg
    optionTemplateGraphRevSeg.scales.x.stacked =  isStackedRevSeg
    for(let i = 0; i < graphDataRevSeg.length; i ++){
      dataTemplateGraphRevSeg.datasets.push(
      {
        label: graphLabelsRevSeg[i], 
        data: graphDataRevSeg[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphRevSeg(<Bar     height={170}  plugins = {plugins} ref ={refGraphRevSeg} data={dataTemplateGraphRevSeg}  options={optionTemplateGraphRevSeg}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataRevSeg, isStackedRevSeg]);


  useEffect(() => {
    dataTemplateGraphRevGeo.datasets = [];
    dataTemplateGraphRevGeo.labels = graphsxAxis;                     
    optionTemplateGraphRevGeo.plugins.title.text =  "Revenue Geography";
    optionTemplateGraphRevGeo.scales.y.stacked =  isStackedRevGeo
    optionTemplateGraphRevGeo.scales.x.stacked =  isStackedRevGeo
    for(let i = 0; i < graphDataRevGeo.length; i ++){
      dataTemplateGraphRevGeo.datasets.push(
      {
        label: graphLabelsRevGeo[i], 
        data: graphDataRevGeo[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphRevGeo(<Bar     height={170}  plugins = {plugins} ref ={refGraphRevGeo} data={dataTemplateGraphRevGeo}  options={optionTemplateGraphRevGeo}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataRevGeo, isStackedRevGeo]);


  useEffect(() => {
    dataTemplateGraphNet.datasets = [];
    dataTemplateGraphNet.labels = graphsxAxis;                     
    optionTemplateGraphNet.plugins.title.text =  "Net Income";
    for(let i = 0; i < graphDataNet.length; i ++){
      dataTemplateGraphNet.datasets.push(
      {
        label: graphLabelsNet[i], 
        data: graphDataNet[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphNet(<Bar  height={170}   plugins = {plugins} ref ={refGraphNet} data={dataTemplateGraphNet}  options={optionTemplateGraphNet}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataNet]);


  useEffect(() => {
    dataTemplateGraphOpEx.datasets = [];
    dataTemplateGraphOpEx.labels = graphsxAxis;          
    optionTemplateGraphOpEx.scales.y.stacked =  isStackedOpEx
    optionTemplateGraphOpEx.scales.x.stacked =  isStackedOpEx          
    optionTemplateGraphOpEx.plugins.title.text =  "Operating Expenses";
    for(let i = 0; i < graphDataOpEx.length; i ++){
      dataTemplateGraphOpEx.datasets.push(
      {
        label: graphLabelsOpEx[i], 
        data: graphDataOpEx[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphOpEx(<Bar  height={170} plugins = {plugins} ref ={refGraphOpEx} data={dataTemplateGraphOpEx}  options={optionTemplateGraphOpEx}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataOpEx,  isStackedOpEx]);

                

  useEffect(() => {
    dataTemplateGraphGrowth.datasets = [];
    dataTemplateGraphGrowth.labels = graphsxAxis;          
    optionTemplateGraphGrowth.scales.y.ticks = {
      callback: (value) => {
        return Math.round(value * 100) + '%'; // Round to the nearest whole number
      },
    }
    optionTemplateGraphGrowth.plugins.title.text =  "Growth";
    for(let i = 0; i < graphDataGrowth.length; i ++){
      dataTemplateGraphGrowth.datasets.push(
      {
        label: graphLabelsGrowth[i], 
        data: graphDataGrowth[i],    
        backgroundColor: colors[i],
        borderColor: colors[i],
      })
    }
    setGraphGrowth(<Line  height={170} plugins = {plugins} ref ={refGraphGrowth} data={dataTemplateGraphGrowth}  options={optionTemplateGraphGrowth}></Line>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataGrowth]);


  useEffect(() => {
    dataTemplateGraphCashFlow.datasets = [];
    dataTemplateGraphCashFlow.labels = graphsxAxis;                     
    optionTemplateGraphCashFlow.plugins.title.text =  "Cash Flow";
    optionTemplateGraphCashFlow.scales.y.stacked =  isStackedCashFlow
    optionTemplateGraphCashFlow.scales.x.stacked =  isStackedCashFlow
    for(let i = 0; i < graphDataCashFlow.length; i ++){
      dataTemplateGraphCashFlow.datasets.push(
      {
        label: graphLabelsCashFlow[i], 
        data: graphDataCashFlow[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphCashFlow(<Bar     height={170}  plugins = {plugins} ref ={refGraphCashFlow} data={dataTemplateGraphCashFlow}  options={optionTemplateGraphCashFlow}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataCashFlow, isStackedCashFlow]);


  useEffect(() => {
    dataTemplateGraphCash.datasets = [];
    dataTemplateGraphCash.labels = graphsxAxis;                     
    optionTemplateGraphCash.plugins.title.text =  "Cash";
    for(let i = 0; i < graphDataCash.length; i ++){
      dataTemplateGraphCash.datasets.push(
      {
        label: graphLabelsCash[i], 
        data: graphDataCash[i],    
        backgroundColor: colors[i],
        borderColor: '#000000',
      })
    }
    setGraphCash(<Bar     height={170}  plugins = {plugins} ref ={refGraphCash} data={dataTemplateGraphCash}  options={optionTemplateGraphCash}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataCash]);


  useEffect(() => {
    dataTemplateGraphMargins.datasets = [];
    dataTemplateGraphMargins.labels = graphsxAxis;          
    optionTemplateGraphMargins.scales.y.ticks = {
      callback: (value) => {
        return Math.round(value * 100) + '%'; // Round to the nearest whole number
      },
    }
    optionTemplateGraphMargins.plugins.title.text =  "Margins";
    for(let i = 0; i < graphDataMargins.length; i ++){
      dataTemplateGraphMargins.datasets.push(
      {
        label: graphLabelsMargins[i], 
        data: graphDataMargins[i],    
        backgroundColor: colors[i],
        borderColor: colors[i],
      })
    }
    setGraphMargins(<Line  height={170} plugins = {plugins} ref ={refGraphMargins} data={dataTemplateGraphMargins}  options={optionTemplateGraphMargins}></Line>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataMargins]);


  useEffect(() => {
    dataTemplateGraphMarCap.datasets = [];
    dataTemplateGraphMarCap.labels = graphsxAxis;          
    optionTemplateGraphMarCap.plugins.title.display =  "true"; 
    optionTemplateGraphMarCap.plugins.title.text =  "Market Cap";
    for(let i = 0; i < graphDataMarCap.length; i ++){
      dataTemplateGraphMarCap.datasets.push(
      {
        label: graphLabelsMarCap[i], 
        data: graphDataMarCap[i],    
        backgroundColor: colors[i],
        borderColor: colors[i],
      })
    }
    setGraphMarCap(<Line  height={170} plugins = {plugins} ref ={refGraphMarCap} data={dataTemplateGraphMarCap}  options={optionTemplateGraphMarCap}></Line>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataMarCap]);


  useEffect(() => {
    dataTemplateGraphOutShare.datasets = [];
    dataTemplateGraphOutShare.labels = graphsxAxis;          
    optionTemplateGraphOutShare.plugins.title.text =  "Outstanding Shares";
    for(let i = 0; i < graphDataOutShare.length; i ++){
      dataTemplateGraphOutShare.datasets.push(
      {
        label: graphLabelsOutShare[i], 
        data: graphDataOutShare[i],    
        backgroundColor: colors[i],
        borderColor: colors[i],
      })
    }
    setGraphOutShare(<Line  height={170} plugins = {plugins} ref ={refGraphOutShare} data={dataTemplateGraphOutShare}  options={optionTemplateGraphOutShare}></Line>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
  }, [graphDataOutShare]);


  function generateGraphSection(graph, title, downloadFunction, stackGraphFunction, activator, shouldCompare, compareMetric, shouldStack) {   //this is where you add graphdata conditions
    if(activator === 0){
      return null; // Return null if activator is empty
    }
    return (

      <>

        <br /><br />


        <div style={{ marginLeft: "-30px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div
            style={{
              height: isSmallScreen ? '500px' : isMediumScreen ? '450px' : '600px', // Adjust the heights for small and larger screens
              width: '85%',
            }}
          >
            {graph}
          </div>
        </div>

        <br /><br />
        {graph ? (
          <div>
            <Grid container justifyContent="center">
              <Grid item>
              {shouldCompare && (
                  <>
                    {premStatus === null || premStatus === false ? (
                      <Button onClick={() => handleOpenPricing()} variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }}>
                        Compare
                      </Button>
                    ) : (
                      <Button component={Link}to={{pathname: '/compare'}}   state={{ id: id.toUpperCase(), metric: compareMetric }} variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }}>
                        Compare
                      </Button>
                    )}
                  </>
                )}
                <span></span>
                {premStatus === null || premStatus === false ? (
                  <Button onClick={() => handleOpenPricing()} variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }}>
                    <DownloadIcon />
                  </Button>
                ) : (
                  <Button variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }} onClick={downloadFunction}>
                    <DownloadIcon />
                  </Button>
                )}
                <span></span>
                {shouldStack && (
                <Button variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }} onClick={stackGraphFunction}>
                  <TocIcon />
                </Button>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <h1></h1>
        )}
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </>
    );
  }

  function generatePremiumGraphSection(graph, title, downloadFunction, stackGraphFunction,activator, shouldCompare, compareMetric, shouldStack) {
    if(activator === 0){
      return null; // Return null if activator is empty
    }
    return (
      <>

{premStatus === null || premStatus === false  && (
  <>
    <Typography variant="h4"
      sx={{
        fontWeight: 'bold',
        fontFamily: 'Roboto Mono, monospace',
        textAlign: 'center',
        fontSize: '20px',
        position: 'relative', // Ensure the title is positioned relative
        zIndex: 1000, // Set a higher z-index value
      }}
      gutterBottom
    >
      {title}
    </Typography>
    </>
        )}



      <div style={{ position: 'relative', flexDirection: 'column', alignItems: 'center' }}>
      <br></br><br></br>




      <div style={{ marginLeft: "-30px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div
            style={{
              height: isSmallScreen ? '500px' : isMediumScreen ? '450px' : '600px', // Adjust the heights for small and larger screens
              width: '85%',
            }}
          >
            {graph}
          </div>
        </div>




      <br></br><br></br>
      {graph ? (
      <div> 
    <Grid container justifyContent="center" >
      <Grid item>
      {shouldCompare && (
        <>
         <Button component={Link}to={{pathname: '/compare'}}   state={{  id: id.toUpperCase(), metric: compareMetric }} variant="outlined" style={{ fontFamily: "Roboto Mono", color: "black", borderColor: "#B8B8B8" }}>
            Compare
          </Button>
        </>)}
      <span></span>
      <Button  variant="outlined" style={{fontFamily: 'Roboto Mono', color:"black" , borderColor: '#B8B8B8'}} onClick={downloadFunction} >
        <DownloadIcon />
      </Button>
      <span></span>
      {shouldStack && (
        <>
          <Button  variant="outlined" style={{fontFamily: 'Roboto Mono', color:"black" , borderColor: '#B8B8B8'}}  onClick={stackGraphFunction} >
            <TocIcon  />
          </Button>
        </>)}

      </Grid>
    </Grid>
        </div>
      ) :(
        <h1></h1>
      )}
    <br /><br /><br /><br /><br /><br /><br /><br /><br />

        {/* Conditional rendering: If the user has not paid and is not premium, show the paywall */}
        {premStatus === null || premStatus === false  && (
          <div
            style={{
              position: 'absolute',
              top: -100,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(249, 249, 249, 0.98)',
              zIndex: 999, // Ensure the paywall appears above the graph
            }}
          >
          <Paper
            sx={{
              width: '60%',
              
              padding: '30px',
              textAlign: 'center',
              fontFamily: 'Roboto Mono, monospace',
              
            }}
          >
            <Typography variant="h5" sx={{
              fontSize: '24px', // Default font size
              fontWeight: 'bold',
              fontFamily: 'Roboto Mono, monospace', // Use Roboto Mono font
              
              '@media (max-width: 600px)': {
                fontSize: '13px', // Adjust font size for smaller screens
              },
            }}>
              ChartingFS Premium
            </Typography>
            <Typography sx={{
                fontSize: '18px',
                margin: '20px 0',
                lineHeight: '1.5',
                fontFamily: 'Roboto Mono, monospace',
                
                '@media (max-width: 600px)': {
                  fontSize: '10px', // Adjust font size for smaller screens
                },
              }}
            >
              Unlock access to this chart
            </Typography>
            <Button
            component={Link} to={`/Pricing`} 
            variant="contained"
            color="primary"
            sx={{
              fontSize: '20px', // Default font size
              fontFamily: 'Roboto Mono, monospace', // Use Roboto Mono font
              textTransform: 'none', // Prevent text from being capitalized
              '@media (max-width: 600px)': {
                fontSize: '8px', // Adjust font size for smaller screens
              },
            }}
          >
            Start 14-Day Free Trial
          </Button>
          </Paper>
          </div>
        )}
      </div>

      </>
    );
  }

  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const handleOpenPricing = () => {
    setIsPricingOpen(true);
  };

  const handleClosePricing = () => {
    setIsPricingOpen(false);
  };

//      {graphDataMargins.some(row => row.some(value => value !== 0)) && generatePremiumGraphSection(graphMargins, "Margins TEST")}
      //
      //    {graphDataRevSeg.length > 0 && ( generateGraphSection(graphRevSeg, "Revenue Segments", createDownloadImageFunction(refGraphRevSeg, "graphRevSeg"), stackedGraphRevSeg))}
    //{graphDataRevGeo.length > 0 && (generateGraphSection(graphRevGeo, "Revenue Geography", createDownloadImageFunction(refGraphRevGeo, "graphRevGeo"), stackedGraphRevGeo))}





  return (
    <>

    <br></br> <br></br>
    <AppBar elevation={0} style={{ height: '80px', background: '#F9F9F9' }} position="static">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="center">
            <Typography onClick={() => handleFrequency("quarterly")} style={{fontWeight:"bold",display: 'inline-block',padding: '8px 16px',backgroundColor: '#E5E5E5',cursor: 'pointer',textDecoration: 'none',color: frequency === 'quarterly' ? 'black' : '#A0A0A0',textDecoration: 'none',fontFamily: 'Roboto Mono',}}>Quarterly</Typography>
            <Typography onClick={() => handleFrequency("annual")} style={{fontWeight:"bold",display: 'inline-block',padding: '8px 16px',backgroundColor: '#E5E5E5',cursor: 'pointer',textDecoration: 'none',color: frequency === 'annual' ? 'black' : '#A0A0A0',textDecoration: 'none',fontFamily: 'Roboto Mono',}}>Annual</Typography>
          </Grid>
          </Toolbar>
    </AppBar>




   {/*     <Grid container alignItems="center" justifyContent="center">

          <FormControl variant="outlined" sx={{fontFamily: 'Roboto Mono',width: isSmallScreen ? '85px':'220px' ,}}>
          {selectedOption === '' && (
        <InputLabel sx={{ fontFamily: 'Roboto Mono', marginBottom: '100px', fontSize: isSmallScreen ? '10px' : '15px' }}>
          Jump To Chart
        </InputLabel>
      )}
        <Select
            size="small"
            label="Jump To Chart"
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{
              fontFamily: 'Roboto Mono',
              background: '#E5E5E5',
              fontWeight: 'bold',
              fontSize: isSmallScreen ? '8px' : '15px',
              wordSpacing: "-2px",
              paddingBottom: "12px",
            }}
          >


<MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Revenue">                      <ScrollLink to="section1" smooth={true} duration={500} spy={true} offset={-200}>
          <Typography sx={{color:"black"}}>Scroll to Element</Typography> 
          </ScrollLink></MenuItem>

      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Revenue">Revenue</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Gross Profit">Gross Profit</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Income">Operating Income</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Expenses">Operating Expenses</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Net Income">Net Income</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Cash">Cash</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Gross Margin">Gross Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Margin">Operating Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Profit Margin">Profit Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Revenue Y/Y">Revenue Y/Y</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="OpEx Y/Y">OpEx Y/Y</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Market Cap">Market Cap</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Outstanding Shares">Outstanding Shares</MenuItem>
        </Select>

      </FormControl>
          </Grid>



        </Toolbar>
    </AppBar>
    <br></br><br></br><br></br><br></br>



     
    <div style={{ backgroundColor: "#E5E5E5", border:"solid 1px", padding:"4px", position: 'fixed', top: '30%', right: '5px', transform: 'translateY(-50%)' }}>
  <Typography variant="subtitle1" sx={{  fontSize: isSmallScreen ? '5px' : '12px', fontFamily: 'Roboto Mono', color: 'black' }}>
    Jump To Chart
  </Typography>
  {chartList.map((item) => (
    <ScrollLink
      key={item}
      to={item.toLowerCase()}
      smooth={true}
      duration={500}
      spy={true}
      offset={-200}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Roboto Mono',
          color: 'black',
          fontSize: isSmallScreen ? '10px' : '13px',
          letterSpacing: isSmallScreen ? '-0.5px' : '-1px',
          paddingBottom: "2px",
          cursor: 'pointer', // Add cursor pointer for hover effect
        }}
      >
        {item}
      </Typography>
    </ScrollLink>
  ))}
</div>

      */}

 


      {/*GRAPH 1 -----------------------------------------------------------------------------------------------------                if its zero dont render graph, shouldCompare, compareMetric, shouldStack                               */}                                  
      <div id="revenue">{generateGraphSection(graphRevOpIn, "Revenue & Operating Income", createDownloadImageFunction(refGraphRevOpIn, "graphRevOpIn"), stackedGraphRevOpIn, graphDataRevOpIn.length, true, "Revenue", true)}</div>
          
      

      {generateGraphSection(graphNet, "Net Income", createDownloadImageFunction(refGraphNet, "graphNetIncome"), "",  graphDataNet.length, true, "Net Income", false)}

      {generateGraphSection(graphOpEx, "Operating Expenses", createDownloadImageFunction(refGraphOpEx, "graphOpEx"),  stackedGraphOpEx,graphDataOpEx.length, true, "Operating Expenses", true)}

      {generateGraphSection(graphCashFlow, "Cash Flow", createDownloadImageFunction(refGraphCashFlow, "graphCashFlow"),  stackedGraphCashFlow,graphDataCashFlow.length, false, "Cash Flow", true)}

      {generateGraphSection(graphCash, "Cash", createDownloadImageFunction(refGraphCash, "graphCash"), "", graphDataCash.length, true, "Cash", false)}


      {generatePremiumGraphSection(graphRevSeg, "Revenue Segments", createDownloadImageFunction(refGraphRevSeg, "graphRevSeg"), stackedGraphRevSeg, graphDataRevSeg.length, false, "", true)}
      {generatePremiumGraphSection(graphRevGeo, "Revenue Geography", createDownloadImageFunction(refGraphRevGeo, "graphRevGeo"), stackedGraphRevGeo, graphDataRevGeo.length, false, "", true)}


      {generatePremiumGraphSection(graphGrowth, "Growth", createDownloadImageFunction(refGraphGrowth, "graphGrowth"),"", graphDataGrowth.length, true, "Revenue Y/Y", false )}

      {generatePremiumGraphSection(graphMargins, "Margins", createDownloadImageFunction(refGraphMargins, "graphMargins") ,"", graphDataMargins.length, true, "Gross Margin", false)}

      {generatePremiumGraphSection(graphMarCap, "Market Cap", createDownloadImageFunction(refGraphMarCap, "graphMarCap") ,"", graphDataMarCap.length, true, "Market Cap", false)}

      {generatePremiumGraphSection(graphOutShare, "Outstanding Shares", createDownloadImageFunction(refGraphOutShare, "graphOutShare"),"", graphDataOutShare.length, true, "Outstanding Shares", false )}
          

      
                <PricingPopup isOpen={isPricingOpen} onClose={handleClosePricing} />

            
    </>  
        
  );
}

export default CompanyInfo;