import React, { Component, useContext, useState, useEffect }  from 'react';
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, List } from '@mui/material';
import { Container, Grid, Card, CardContent, Typography, Button,CssBaseline, AppBar, Toolbar } from '@mui/material';
import useAxios from '../../api/useAxiosPrivate';
import DownloadIcon from '@mui/icons-material/Download';
import UpdateIcon from '@mui/icons-material/Update';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FeedIcon from '@mui/icons-material/Feed';
import CreateIcon from '@mui/icons-material/Create';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// /https://mui.com/material-ui/getting-started/templates/


const PricingPage = () => {

  const location = useLocation();
  //const metric = location.state && location.state.metric;
  //const id =  location.state.id;
 
  const id = location.state && location.state.id;
  //console.log(location.state)
  //console.log(id)






  //const classes = useStyles();
  const axiosPrivate = useAxios();
  //let {user, logoutUser} = useContext(AuthContext)
  //console.log(user.premium)
  const currentUrl = window.location.href; // Get the current URL
  const [sessionId, setSessionId] = useState("");
  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)

  const [premStatus, setPremStatus] = useState(null);
  const [premInterval, setPremInterval] = useState("");


  const checkoutHandler1 = async (e) => {
    e.preventDefault();
  
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    //var lookup_key = "regular"
    var price_id = "month"
    try {
      //const { data } = await axios.post(                                // potentially make axiosPrivate
      const { data } = await axiosPrivate.post(                                // potentially make axiosPrivate
        "/api/checkout/create-checkout-session",
        //{ lookup_key, price_id},
        {price_id, user},             //need to change auth method from either user, or local storage token, SHOULD PROLLY BE LOCAL STOAGE, auth context is probably only for UI
        config, { withCredentials: true }
      );
      //console.log(data)
      window.location.href = data.url
      
    /*try {
      await axios.post(
        "/api/checkout/hiya",
        { lookup_key},
        config
      );*/
    } catch (error) {
  
    }
  };



  const checkoutHandler2 = async (e) => {
    e.preventDefault();
  
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    //var lookup_key = "regular"
    var price_id = "year"
    try {
      //const { data } = await axios.post(                                // potentially make axiosPrivate
      const { data } = await axiosPrivate.post(                                // potentially make axiosPrivate
        "/api/checkout/create-checkout-session",
        //{ lookup_key, price_id},
        {price_id, user},             //need to change auth method from either user, or local storage token, SHOULD PROLLY BE LOCAL STOAGE, auth context is probably only for UI
        config, { withCredentials: true }
      );
      //console.log(data)
      window.location.href = data.url
      
    /*try {
      await axios.post(
        "/api/checkout/hiya",
        { lookup_key},
        config
      );*/
    } catch (error) {
  
    }
  };



  const manageHandler = async (e) => {
    
    e.preventDefault()                        //this is important because if its not there it brings you back. 
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    //var session_id = sessionId

    try {
      const { data } = await axiosPrivate.post(
        "/api/checkout/create-portal-session",
        {user,  currentUrl },
        config, { withCredentials: true }
      );
      //console.log(data)
      window.location.href = data.url
    } catch (error) {
  
    }
  };


  const upgradePlan = async (e) => {
    
    e.preventDefault()                        //this is important because if its not there it brings you back. 
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };



    try {
      const { data } = await axiosPrivate.post(
        "/api/checkout/upgrade",
        {user,  currentUrl },
        config, { withCredentials: true }
      );
      //console.log(data)
      window.location.href = data.url
    } catch (error) {
  
    }


  };


  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const { data } = await axiosPrivate.get("/api/auth/authorize", config, { withCredentials: true });
        setPremStatus(data.status);
        setPremInterval(data.interval);
      } catch (error) {
        // Handle errors more accurately, e.g., distinguish between network errors and unauthorized errors
        console.error("Error fetching premium status:", error);
        setPremStatus(false);
      }
    };

    fetchPrivateData();
  }, []);



  const textStyle = {
    fontFamily: 'Roboto Mono, monospace',
  };

  const items = [
    {
      title: 'All Company Data',
      description: 'Have access to all available company data',
      imageUrl: 'url_to_your_image_4.jpg',
      icon: <WorkspacePremiumIcon  style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    {
      title: 'Compare Financials',
      description: 'Compare any financial metric of multiple companies over time',
      //description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_2.jpg',
      icon: <CompareArrowsIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    {
      title: 'Historical Stock Price Charts',
      description: 'Unlock access to historical stock price charts for period longer than one month.',
      imageUrl: 'url_to_your_image_4.jpg',
      icon: <ShowChartIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    {
      title: 'Favorites',
      description: 'Save your favorite companies to have them all on standby.',
      //description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_3.jpg',
      icon: <FavoriteIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },

    {
      title: 'Financial Forecasts',
      //description: 'Forecast future financials and business metrics of any company and share those forecasts with your followers.',
      description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_1.jpg',
      icon:<UpdateIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    {
      title: 'Toggle between Chart and Data view',
      //description: 'View the underlying data for any financial chart.',
      description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_3.jpg',
      icon: <FeedIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    {
      title: 'Private Financials',
      //description: 'Visualize any, or your own company financial data, and share it using a dedicated page.',
      description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_4.jpg',
      icon: <CreateIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },
    /*{
      title: 'Crypto',
      //description: 'Access to all Crypto data and charts.',
      description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_4.jpg',
      icon: <CurrencyBitcoinIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },*/
    /*{
      title: 'Sync Earnings',
      //description: 'Sync the earnings calendar of your favorite companies or all companies to your device.',
      description: 'Coming Soon!',
      imageUrl: 'url_to_your_image_4.jpg',
      icon: <DownloadIcon style={{ color: 'darkgray', fontSize: '48px' }}  />, // Use a different icon for each item
    },*/

    // Add more items as needed
  ];



  const [subscriptionType, setSubscriptionType] = useState('monthly');

  const toggleSubscriptionType = () => {
    if (subscriptionType === 'yearly') {
      setSubscriptionType('monthly');
    } else {
      setSubscriptionType('yearly');
    }
  };

  const getSubscriptionText = () => {
    if (subscriptionType === 'yearly') {
      return (
        <>
          <Typography variant="subtitle1" gutterBottom style={textStyle}>
            Then $99 per year
          </Typography>
          <Typography variant="body2" style={{ color: 'blue', cursor: 'pointer', ...textStyle, fontSize: '0.8rem' }} onClick={toggleSubscriptionType}>
            Switch to monthly
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="subtitle1" gutterBottom style={textStyle}>
            Then $10 per month
          </Typography>
          <Typography variant="body2" style={{ color: 'blue', cursor: 'pointer', ...textStyle, fontSize: '0.8rem' }} onClick={toggleSubscriptionType}>
            Switch to yearly
          </Typography>
        </>
      );
    }
  };

  const getSubscriptionTextV2 = () => {
      return (
        <>
                <AppBar elevation={0} style={{ height: '30px', backgroundColor: '#F0F0F0' }} position="static">
        <Toolbar>
          <Grid container justifyContent="center" style={{ color:'black'}} >

          <Typography variant="h5" gutterBottom style={{ ...textStyle, fontWeight: 'bold' }}>
            Monthly
          </Typography>

          </Grid>
          <Grid container justifyContent="center" style={{ color:'black'}} >
          <Typography variant="h5" gutterBottom style={{ ...textStyle, fontWeight: 'bold' }}>
            Annual
          </Typography>

          </Grid>
          </Toolbar>
        </AppBar>

        <AppBar elevation={0} style={{ height: '40px', backgroundColor: '#F0F0F0' }} position="static">
        <Toolbar>
          <Grid container justifyContent="center" style={{ color:'black'}} >

          <Typography variant="subtitle1" gutterBottom style={{ ...textStyle, fontWeight: "bold", fontSize:"14px" }}>
            $10/month
          </Typography>

          </Grid>
          <Grid container justifyContent="center" style={{ color:'black'}} >
          <Typography variant="subtitle1" gutterBottom style={{...textStyle, fontWeight: "bold", fontSize:"14px"}}>
            $99/year
          </Typography>

          </Grid>
          </Toolbar>
        </AppBar>

        </>
      );
    }






  return (
    <div>

<Container maxWidth={true} style={{ backgroundColor: '#f0f0f0', minHeight: '35vh', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '2rem' }}>
    <br></br>
    <Typography variant="h4" component="div" gutterBottom style={{ ...textStyle, fontSize: '2rem', textAlign: 'center' }}>
    ChartingFS <strong>Premium</strong>
</Typography>
      <br></br>
      <Typography variant="h5" gutterBottom style={{ ...textStyle, fontWeight: 'bold' }}>
      14-Day Free Trial
    </Typography>
    <br></br><br></br>
    {/*getSubscriptionText()*/}
    {getSubscriptionTextV2()}

    
    <AppBar elevation={0}  style={{ height: '80px', backgroundColor: '#F0F0F0' }} position="static">
        <Toolbar>
          <Grid container justifyContent="center"  >
          {premStatus === true && user ? (
        <div>
          <Box component="form" onSubmit={manageHandler} sx={{ mt: 1 }}>
            <Button type="submit" variant="contained" disableElevation style={{ fontSize:"14px", cursor: 'pointer', textTransform: 'none', ...textStyle }}>
              Manage Plan
            </Button>
          </Box>
        </div>
      ) : premStatus === false   && user ? (
        <div>
        <Box component="form" onSubmit={checkoutHandler1} sx={{ mt: 1 }}>
          <Button type="submit" variant="contained" disableElevation style={{fontSize:"14px", cursor: 'pointer', textTransform: 'none', ...textStyle }}>
            Subscribe
          </Button>
        </Box>
      </div>  
      ) : premStatus === null && user ? (
        <div>
        </div>            
      ) :  (
        <div>
          <Button component={Link} to={`/login`}  type="submit" variant="contained" disableElevation style={{fontSize:"14px", cursor: 'pointer', textTransform: 'none', ...textStyle }}>
            Subscribe
          </Button>
        </div>
      )}
          </Grid>
          <Grid container justifyContent="center"  >
          {premStatus === true && user ? (
        <div>
          <Box component="form" onSubmit={manageHandler} sx={{ mt: 1 }}>
            <Button type="submit" variant="contained" disableElevation style={{fontSize:"14px", cursor: 'pointer', textTransform: 'none', ...textStyle }}>
              Manage Plan
            </Button>
          </Box>
        </div>
      ) : premStatus === false   && user ? (
        <div>
        <Box component="form" onSubmit={checkoutHandler2} sx={{ mt: 1 }}>
          <Button type="submit" variant="contained" disableElevation style={{fontSize:"14px", cursor: 'pointer', textTransform: 'none', ...textStyle }}>
            Subscribe
          </Button>
        </Box>
      </div>  
      ) : premStatus === null && user ? (
        <div>
        </div>            
      ) :  (
        <div>
          <Button component={Link} to={`/login`}  type="submit" variant="contained" disableElevation style={{ fontSize:"14px",cursor: 'pointer', textTransform: 'none', ...textStyle }}>
            Subscribe
          </Button>
        </div>
      )}
          </Grid>
          </Toolbar>
        </AppBar>
    </Container>
  

    <br></br><br></br><br></br><br></br><br></br><br></br>
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} md={6} lg={6} key={index} style={{ marginBottom: '100px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {item.icon}
              <Typography variant="h6" style={{ fontWeight: 'bold', fontFamily: 'Roboto Mono' }}>{item.title}</Typography>
              <Typography variant="body2" style={{ color: 'darkgray', fontFamily: 'Roboto Mono', maxWidth: '400px' }}>
                {item.description}
              </Typography>
            </Box>
          </div>
        </Grid>
      ))}
    </Grid>



 




      
    </div>
  );
};

export default PricingPage;