import axios from 'axios';      //used for api calls
import useAxios from './useAxiosPrivate'




//const API = axios.create({ baseURL: 'http://localhost:5000'})
//const API = axios.create({ baseURL: 'https://chartingfs-c1c9a3da89a5.herokuapp.com/'})
  
//const API = axios.create({ baseURL: process.env.REACT_APP_API})

const url3 = 'https://api.coingecko.com/api/v3/coins/'
const url4 = '/api/finance'

const API = axios.create({ baseURL: 'https://app.chartingfs.com/'})
//const API = axios.create({ baseURL: 'http://localhost:5000/'})



//WHICH ONES NEED AXIOSPRIVATE?
// AXIOS PRIVATE CHECKS AUTH TOKENS
//  const axiosPrivate = useAxios();
//NON AxiosPrivate calls require CONFIG. Axios Private handles config in itself, anything else needs to be manually put

//export const fetchCrypto = () => API.get(`/crypto`)
export const fetchCrypto = () => API.get(url3);  
//export const fetchFinanceData = (ticker) => axios.get('/api/company/' + `${ticker}`);  
export const refresh = () => API.get("/api/auth/refresh", { withCredentials: true },);   // no need for axiosPrivate, since this is refresh


export {API}



//export const fetchCompanyFacts = (CIK) => axios.get('https://data.sec.gov/api/xbrl/companyfacts/CIK' + CIK +'.json'); 
