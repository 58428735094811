
import React, { useState, useEffect, useRef, useCallback , useContext} from 'react';


import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { Button, Container, List, ListItem, ListItemText, TableCell, TableRow, TableContainer, Paper, Table, TableHead, TableBody, Box, CardMedia } from '@mui/material';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';

import { useNavigate } from "react-router-dom";
import abbreviateNumber from '../utils/abbreviateNumber';
import useMediaQuery from '@mui/material/useMediaQuery';

import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { BrowserRouter, useLocation } from 'react-router-dom';

import PricingPopup from '../account/pricing/PricingPopUp';
import { API } from '../api/index';
import moment from 'moment';
import useAxios from '../api/useAxiosPrivate';
import AuthContext from '../context/AuthContext';


const CompanyNews = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {id} = useParams();
  const axiosPrivate = useAxios();

  const location = useLocation();
  const currentPath = location.pathname;
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [imageSize, setImageSize] = useState({ width: 'auto', height: 'auto' });

  const [news, setNews] = useState([]);
  const [visibleNewsCount, setVisibleNewsCount] = useState(30); // Number of initially visible news articles
  const [error, setError] = useState("");

  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)


  const formatDate = (rawDate) => {
    const dateObject = moment(rawDate);
    const formattedDate = dateObject.format('MMM D, YYYY');
    return formattedDate;
  };



  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        const [quoteResponse] = await Promise.all([
          API.post("/api/data/news", { id }, config, { withCredentials: true }),

        ]);
  
        const quote = quoteResponse.data;
        setNews(quote)
        console.log(quote)

      } catch (error) {
        setError(error);
      }
    };
  
    fetchFinancialData();
  }, []);

  const handleLoadMore = () => {
    // Increase the visible news count by a certain number (e.g., 5) when the "Load More" button is clicked
    setVisibleNewsCount(visibleNewsCount + 10);
  };


  const styles = {
    media: {
      height: 170,
      //paddingTop: '56.25%', // 16:9,
      marginTop:'30'
    }
};


  return (
    <>
    <br></br><br></br>    

<Container style={{ textAlign: 'center', maxWidth: '90%' }} justifycontent='space-between' alignitems='stretch' spacing={2} >

<Typography sx={{fontFamily: 'Roboto Mono', fontWeight: 'bold', fontSize: isSmallScreen ? '20px' : '25px', }} >  {news.length > 0 ? news[0].symbol + " News": ''} </Typography>
  <br></br><br></br><br></br>      
  <List>
    {news.slice(0, visibleNewsCount).map((article, index) => {
      // Truncate the article.text to 200 characters
      var articleLength = isSmallScreen ? 100 : 240;
      const truncatedText =
        article.text.length > articleLength
          ? article.text.slice(0, articleLength) + '...'
          : article.text;

    return (
      <Link
        to={article.url}
        key={index}
        style={{ textDecoration: 'none', marginBottom: '10px' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItem
          style={{
            border: '1px solid #ccc',
            borderRight: 'none',
            borderLeft: 'none',
            borderTop: 'none',
            height: '200px',
            marginBottom: '10px',
          }}
        >
          <Grid container spacing={2}>
          {isSmallScreen ? null : (
            <Grid item xs={3}>
              <CardMedia
                component="img"
                image={article.image}
                style={styles.media} 
              />
            </Grid>
            )}
            <Grid item xs={isSmallScreen ? 9 : 7}>
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'black', fontWeight: 'bold', fontSize: isSmallScreen ? "14px" : "20px", }}>
                {article.title}
              </Typography>
              <br />
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'grey', fontSize: isSmallScreen ? "12px" : "13px", }}>
                {truncatedText}
              </Typography>
            </Grid>
            <Grid item xs={isSmallScreen ? 3 : 2}>
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'black', marginBottom: '80px', fontSize: isSmallScreen ? "10px" : "13px", fontWeight:"bold" }}>
                {article.symbol} * {formatDate(article.publishedDate)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Link>
    );
  })}
</List>
    
    {visibleNewsCount < news.length && (
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        )}
  </Container>


        </>

    );
  };
    
export default CompanyNews;
    