import { useState, useContext } from 'react';
// @mui
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_

import React, { Component }  from 'react';
import AuthContext from '../../context/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { logoutUser, user } = useContext(AuthContext);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    setOpen(null);
    logoutUser();
  };

  return (
    <>

        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
          <AccountCircleIcon  />
        </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 120,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >


        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>

            <MenuItem onClick={handleClose} component={Link} to="/youraccount" >
              Account
            </MenuItem>
          
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>

            <MenuItem  onClick={handleLogout}>
              Logout
            </MenuItem>
          
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Popover>
    </>
  );
}