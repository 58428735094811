import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Container, Typography}from '@mui/material'
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useResponsive from '../../layouts/hooks/useResponsive';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Divider, AppBar, Toolbar, Grid} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UpdateIcon from '@mui/icons-material/Update';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FeedIcon from '@mui/icons-material/Feed';
import CreateIcon from '@mui/icons-material/Create';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import useAxios from '../../api/useAxiosPrivate';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';




const PricingPopup = ({ isOpen, onClose }) => {
    const currentUrl = window.location.href; // Get the current URL
    const axiosPrivate = useAxios();
    const isDesktop = useResponsive('up', 'lg'); 
    const NAV_WIDTH = 280;
    const [premStatus, setPremStatus] = useState(null);
    let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)

    const isSmallScreen = useMediaQuery('(max-width:600px)');






    const [selectedOption, setSelectedOption] = useState("monthly");

    const handleOptionChange = (option) => {
      setSelectedOption(option);
    };


    useEffect(() => {
        const fetchPrivateData = async () => {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          try {
            const { data } = await axiosPrivate.get("/api/auth/authorize", config, { withCredentials: true });
            setPremStatus(data.status);
          } catch (error) {
            // Handle errors more accurately, e.g., distinguish between network errors and unauthorized errors
            console.error("Error fetching premium status:", error);
            setPremStatus(false);
          }
        };
    
        fetchPrivateData();
      }, []);



    const checkoutHandler1 = async (e) => {
        e.preventDefault();
      
        const config = {
          header: {
            "Content-Type": "application/json",
          },
        };
        var price_id = "month"
        try {                       
          const { data } = await axiosPrivate.post(                             
            "/api/checkout/create-checkout-session",
            {price_id, user},            
            config, { withCredentials: true }
          );
   
          window.location.href = data.url
          
        } catch (error) {
      
        }
      };
    
    
    
      const checkoutHandler2 = async (e) => {
        e.preventDefault();
      
        const config = {
          header: {
            "Content-Type": "application/json",
          },
        };

    var price_id = "year"
        try {          
          const { data } = await axiosPrivate.post(                                
            "/api/checkout/create-checkout-session",

            {price_id, user},             
            config, { withCredentials: true }
          );
   
          window.location.href = data.url
          

        } catch (error) {
      
        }
      };
    
    
    
      const manageHandler = async (e) => {
        
        e.preventDefault()                        
        const config = {
          header: {
            "Content-Type": "application/json",
          },
        };
    
        try {
          const { data } = await axiosPrivate.post(
            "/api/checkout/create-portal-session",
            {user,  currentUrl },
            config, { withCredentials: true }
          );
  
          window.location.href = data.url
        } catch (error) {
      
        }
      };








    const bulletPoints = [
        {
          text: 'Access All Company Data',
          icon: <CompareArrowsIcon style={{ color: '#4383CC', fontSize: '30px' }}/>, // Replace with the desired Material-UI icon
        },
        {
          text: 'Compare Companies',
          icon: <ShowChartIcon style={{ color: '#4383CC', fontSize: '30px' }}  />, // Replace with the desired Material-UI icon
        },
        {
          text: 'Active News Updates',
          icon: <UpdateIcon style={{ color: '#4383CC', fontSize: '30px' }}/> // Replace with the desired Material-UI icon
        },
        {
          text: 'Historical Stock Price Charts',
          icon: <FeedIcon style={{ color: '#4383CC', fontSize: '30px' }}/>, // Replace with the desired Material-UI icon
        },
        {
          text: 'Favorite Lists',
          icon: <CreateIcon style={{ color: '#4383CC', fontSize: '30px' }}/>, // Replace with the desired Material-UI icon
        },

      ];


    return (
        <Dialog open={isOpen} onClose={onClose}   BackdropProps={{
          style: {
            
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust the alpha (last value) for darkness
            boxShadow: 'none', // No box shadow
          },
        }} PaperProps={{
            style: {
                border:"solid 2px",
              borderRadius: '10px', // Adjust the radius value as needed
              borderColor: "#4383CC",
              backgroundColor: 'transparent', // Ensure the dialog is transparent
              boxShadow: "none",
              
            },
          }} style={{ marginLeft: isDesktop ? '280px' : '0px' }}> {/* Set maxWidth to control the width */}

   <Container  maxWidth="md" sx={{ textAlign: 'center', marginTop: '00px',  backgroundColor:"#E5E5E5",  }}>
        <DialogActions style = {{marginBottom:"-30px"}}>
            <Typography
            onClick={() => onClose()}
            style={{
                display: 'inline-block',
                cursor: 'pointer',
                fontFamily: 'Roboto Mono',
                fontSize:"14px",
                paddingTop:"12px",
                
            }}
            >
          <CloseIcon/>
        </Typography>
          </DialogActions>
          {/*width: '500px',  height: '560px',    */}
          
          <DialogContent style={{ width: isSmallScreen ? '220px' : '500px'  ,    height: isSmallScreen ? '560px' : '560px' }}>



          <WorkspacePremiumIcon style = {{color: "#4383CC" ,fontSize: "30px"}} />
          <br></br>
          <Typography variant="h6" style={{ color: 'black', fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '20px' : '30px'}}>  ChartingFS Premium </Typography>
          <Divider sx={{ width: '87%', height: '1px', marginLeft: 'auto',marginRight: 'auto', marginTop:"10px", marginBottom:"10px",  }}/>
          <List>
            {bulletPoints.map((point, index) => (
                <ListItem key={index} style={{ marginLeft: isSmallScreen ? '-20px' : '20px' }}>
                {point.icon}
                <ListItemText
                    primary={
                    <Typography style={{fontSize: isSmallScreen ? '13px' : '18px' ,fontFamily: 'Roboto Mono', fontWeight: 'bold',  marginRight: isSmallScreen ? '-40px' : '-20px'}}>
                        {point.text}
                    </Typography>
                    }
                    style={{ marginLeft: '30px' }} // Adjust the maximum width as needed
                />
                </ListItem>
            ))}
            </List>
        <Divider sx={{ width: '87%', height: '1px', marginLeft: 'auto',marginRight: 'auto', marginTop:"10px", marginBottom:"7px", }}/>

        <AppBar elevation={0} style={{ height: '80px', backgroundColor:"#E5E5E5"}} position="static">
                <Toolbar>
                <Grid container alignItems="center" justifyContent="center" >
                    <Typography  onClick={() => handleOptionChange("monthly")} style={{fontSize: isSmallScreen ? '12px' : '15px',backgroundColor: selectedOption === "monthly" ? "#4383CC" : "#E5E5E5",border: "solid 2px", borderRadius:"8px",borderTopRightRadius:"0px",borderBottomRightRadius:"0px", borderRight:"solid 0px",fontWeight:"bold",display: 'inline-block',borderColor: "#4383CC", padding: '8px 16px',cursor: 'pointer',fontFamily: 'Roboto Mono', color: selectedOption === "monthly" ? "white" : "#000",opacity: selectedOption === "monthly" ? 1 : 0.5,}}>Monthly</Typography>
                    <Typography  onClick={() => handleOptionChange("annual")} style={{fontSize: isSmallScreen ? '12px' : '15px',backgroundColor: selectedOption === "annual" ? "#4383CC" : "#E5E5E5",border: "solid 2px", borderRadius:"8px", borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", borderLeft:"solid 0px",fontWeight:"bold",display: 'inline-block',borderColor: "#4383CC",padding: '8px 16px',cursor: 'pointer',fontFamily: 'Roboto Mono',      color: selectedOption === "annual" ? "white" : "#000",  opacity: selectedOption === "annual" ? 1 : 0.5,}}>Annual</Typography>
                </Grid>
                
                </Toolbar>
                <Typography  style={{ fontSize: isSmallScreen ? '11px' : '16px',borderRadius:"3px",fontWeight:"bold",color:"black",display: 'inline-block',cursor: 'pointer',fontFamily: 'Roboto Mono',}}> {selectedOption === "monthly" ? "$10/month" : "$99/year"}</Typography>
            </AppBar>
                    <br></br>
                    {premStatus === false   && user ? (
        <div>
        <Button
            onClick={selectedOption === 'monthly' ? checkoutHandler1 : checkoutHandler2}
                sx={{
                  color: "#FFFFFF", // White text color
                  backgroundColor: "#4383CC", // Background color
                  fontFamily: "Roboto Mono", // Set font to Roboto Mono
                  fontSize: isSmallScreen ? '12px' : '14px',
                  fontWeight:"bold",
                  textTransform: "none", // Prevent text transformation
                   border:"solid 2px",
                  borderRadius:"5px",
                  borderColor: "#4383CC",
                  "&:hover": {
                  backgroundColor: "#4383CC", // Background color on hover
                  },
                  marginTop: isSmallScreen ? '-15px' : '12px',
                  padding: isSmallScreen ? '9px 20px' : "10px 50px",
      
            }}
            >
            Start 14-Day Free Trial
        </Button>
        </div>
      ) : premStatus === null && user ? (
        <div>
        </div>            
      ) :  (
        <div>
        <Button
        component={Link} to={`/login`}
        sx={{
            color: "#FFFFFF", // White text color
            backgroundColor: "#4383CC", // Background color
            fontFamily: "Roboto Mono", // Set font to Roboto Mono
            fontSize: isSmallScreen ? '12px' : '14px',
            fontWeight:"bold",
            textTransform: "none", // Prevent text transformation
             border:"solid 2px",
            borderRadius:"5px",
            borderColor: "#4383CC",
            "&:hover": {
            backgroundColor: "#4383CC", // Background color on hover
            },
            marginTop: isSmallScreen ? '-10px' : '12px',
            padding: isSmallScreen ? '9px 20px' : "10px 50px",

        }}
        >
        Start 14-Day Free Trial
        </Button>
        </div>
      )}
          </DialogContent>
          


        {/*console.log("ran")*/}





          </Container>
        </Dialog>
      );
    };
    
    export default PricingPopup;