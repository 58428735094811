import React, { useContext, useState, useEffect }  from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box
} from '@mui/material';
import useAxios from '../../api/useAxiosPrivate';
import AuthContext from '../../context/AuthContext';
import { CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';



const ProfileSettings = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const axiosPrivate = useAxios();
  const currentUrl = window.location.href; // Get the current URL
  const [sessionId, setSessionId] = useState("");
  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)
  const [premStatus, setPremStatus] = useState(null);
  const [premInterval, setPremInterval] = useState("");
  const [newEmail, setNewEmail] = useState('');
  const [newName, setNewName] = useState('');
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState('');
  const isSmallScreen = useMediaQuery('(max-width:650px)');

  
  const handleEdit = () => {
    if (isEditing) {

      setNewName('');
      setNewEmail('');
      setNameError('');
      setEmailError('');
    }
    setIsEditing(!isEditing);
    setNewName(name);
    setNewEmail(email);
  };

  const handleSave = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      let hasError = false;
  
      if ((newEmail !== email) && (newEmail !== '')) {
        try {
          await axiosPrivate.patch(
            '/api/auth/updateemail',
            { newEmail: newEmail }, 
            config, { withCredentials: true }
          );
        } catch (error) {
          setEmailError(error.response.data.error);
          hasError = true;
        }
      }
  
      if ((newName !== name) && (newName !== '')) {
        try {
          await axiosPrivate.patch(
            '/api/auth/updatename',
            { newName: newName },
            config, { withCredentials: true }
          );
        } catch (error) {
          setNameError(error.response.data.error);
          hasError = true;
        }
      }
  
      if (!hasError) {
        // Update state only if there are no errors
        if (newEmail) {
          setEmail(newEmail);
          setNewEmail('');
        }
        if (newName) {
          setName(newName);
          setNewName('');
        }
        setEmailError('');
        setNameError('');
  
        // Close editing mode when both email and name updates are successful
        setIsEditing(false);
      }
  
    } catch (error) {
      if ((error.response.data.error).includes('Name')) {
        setNameError(error.response.data.error);
      }
      if ((error.response.data.error).includes('Email')) {
        setEmailError(error.response.data.error);
      }
      setNewName('');
      setNewEmail('');
    }
  
    // Always set a timeout to clear errors after a certain time
    setTimeout(() => {
      setEmailError('');
      setNameError('');
    }, 5000);
  };

  const manageHandler = async (e) => {
    
    e.preventDefault()                        //this is important because if its not there it brings you back. 
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axiosPrivate.post(
        "/api/checkout/create-portal-session",
        {user,  currentUrl },
        config, { withCredentials: true }
      );
      //console.log(data)
      window.location.href = data.url
    } catch (error) {
    }

  };


  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const { data } = await axiosPrivate.get("/api/auth/info", config, { withCredentials: true },);
        setPremStatus(data.status);
        setPremInterval(data.interval);
        setEmail(data.user.email)
        setName(data.user.name)
      } catch (error) {
        console.error("Error fetching premium status:", error);
        setPremStatus(false);
      }
    };

    fetchPrivateData();
  }, []);






  return (
    <Container maxWidth="md">
      <br />
      <br />
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Roboto Mono' }}><strong>Your Account</strong></Typography>
      <br />
      <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#F9F9F9' }}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Roboto Mono' }}>
          Profile Settings
          <Button
            variant="outlined"
            onClick={handleEdit}
            sx={{ marginLeft: 'auto', fontFamily: 'Roboto Mono' }}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{ marginLeft: 2, fontFamily: 'Roboto Mono' }}
            >
              Save
            </Button>
          )}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono" style={{ fontSize: isSmallScreen ? '14px' : '20px' }}>Name</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            {isEditing ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  fullWidth
                  label="Name"
                  value={newName}
                  autoComplete="off"
                  onChange={(e) => setNewName(e.target.value)}
                />
                {nameError && <p style={{ color: 'red', fontFamily: 'Roboto Mono',fontSize: isSmallScreen ? '10px' : '16px' }}>{nameError}</p>}
              </div>
            ) : (
              <Typography fontFamily="Roboto Mono" fontSize={ isSmallScreen ? '10px' : '16px'}>{name}</Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono" style={{ fontSize: isSmallScreen ? '14px' : '20px' }}>Email</Typography>
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <div>
                <TextField
                  fullWidth
                  label="Email"
                  value={newEmail}
                  autoComplete="off"
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                {emailError && <p style={{ color: 'red', fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '16px' }}>{emailError}</p>}
              </div>
            ) : (
              <Typography fontFamily="Roboto Mono" fontSize={ isSmallScreen ? '10px' : '16px'}>{email}</Typography> 
            )}
          </Grid>
          {/*<Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono">Password</Typography>
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            ) : (
              <Typography fontFamily="Roboto Mono">********</Typography>
            )}
            </Grid>*/}
        </Grid>
      </Paper>
      <br />
      <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#F9F9F9' }}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Roboto Mono' }}>
          Subscription
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono" style={{ fontSize: isSmallScreen ? '14px' : '20px' }}>Premium</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            {premStatus === true ? (
              <CheckCircleOutline sx={{ color: 'green' }} />
            ) : premStatus === false ? (
              <CancelOutlined sx={{ color: 'red' }} />
            ) : null}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono" style={{ fontSize: isSmallScreen ? '14px' : '20px' }}>Plan</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            {premInterval == "month" ? (
              <Typography fontFamily="Roboto Mono" style={{fontSize: isSmallScreen ? '10px' : '16px' }}>Monthly</Typography>
            ) : premInterval == "year" ? (
              <Typography fontFamily="Roboto Mono"style={{fontSize: isSmallScreen ? '10px' : '16px' }}>Yearly</Typography>
            ) : <Typography fontFamily="Roboto Mono"style={{fontSize: isSmallScreen ? '10px' : '16px' }}>No Current Plan Active</Typography>}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" fontFamily="Roboto Mono" fontSize={ isSmallScreen ? '14px' : '20px'}>Manage Subscriptions</Typography>
          </Grid>
          <Grid item xs={6}>
            {premStatus === true ? (
              <Box component="form" onSubmit={manageHandler} sx={{ mt: 1 }}>
                <Button type="submit" variant="contained" sx={{ marginLeft: 0, fontFamily: 'Roboto Mono' , fontSize: isSmallScreen ? '10px' : '16px' }}>
                  Manage Plan
                </Button>
              </Box>
            ) : premStatus === false ? (
              <>
                <Link to="/pricing">
                  <Button type="submit" variant="contained" disableElevation style={{ cursor: 'pointer', textTransform: 'none', fontFamily: 'Roboto Mono'  , fontSize: isSmallScreen ? '10px' : '16px'}}>
                    Subscribe
                  </Button>
                </Link>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProfileSettings;


