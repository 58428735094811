const plugins = [{

  beforeDraw: function(chart) {
    var ctx = chart.ctx;
    ctx.fillStyle = "#F9F9F9";
    ctx.fillRect(0, 0, chart.width, chart.height);
  },

  afterDraw: function(chart) {
    var width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    //var fontSize = (height / 400).toFixed(2); // Smaller font size
    var fontSize = (height / 35).toFixed(2); // Smaller font size
    //=ctx.font = fontSize + "em sans-serif";
    ctx.font ='11px' + "'Roboto Mono', monospace";
    //ctx.font =fontSize + "px"+ "'Roboto Mono', monospace";

    //ctx.fillStyle = "rgba(210, 215, 211, 1)";
    ctx.fillStyle = "rgba(180, 185, 181, 0.8)"; // Slightly darker color
    ctx.textBaseline = "top";


    var text = "ChartingFS.com";


    /*var textX = Math.round((width - ctx.measureText(text).width) / 2);
    var textY = height / 2;
    ctx.fillText(text, textX, textY);*/

    // Use below Setting for download only
    var textX = width - ctx.measureText(text).width - 10; // Adjust the X position to the right
    var textY = 35; // Y position of the second line of text
    ctx.fillText(text, textX, textY);

    ctx.save();
  },
}];

export default plugins;