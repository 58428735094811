import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Button, Box, TextField, TableCell, TableContainer, Paper , Table, TableBody, TableRow, TableHead} from '@mui/material';
import {useMediaQuery} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import us_symbols from "../utils/us_symbols.json"
import useAxios from '../api/useAxiosPrivate';

import AuthContext from '../context/AuthContext'
import axios from 'axios';
import { API } from '../api';
import { Link, useNavigate } from 'react-router-dom';
//import DateFnsUtils from '@date-io/date-fns';

const Calendar = () => {
  const axiosPrivate = useAxios();
  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)
  const navigate = useNavigate(); // Use useNavigate hook
  const [companyData, setCompanyData] = useState();
  const [privateData, setPrivateData] = useState("");
  const [privateData2, setPrivateData2] = useState("");
  const [privateData3, setPrivateData3] = useState("");
  const [error, setError] = useState("");
  const isSmallScreenLabel = useMediaQuery('(max-width:600px)');
  const [text, setText] = useState('');

  

      useEffect(() => {

        const fetchPrivateData3 = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
          try {
            //const { data } = await axiosPrivate.get("/api/auth/info");   //Chrome will log all the network related error messages no matter what.
            const { data } = await API.post("/api/data/quote", { id: "AMZN" } , config, { withCredentials: true });   //Chrome will log all the network related error messages no matter what.
            console.log(data)
            //const { data } = await axiosPrivate.get("/api/private", config);   //Chrome will log all the network related error messages no matter what.
            setPrivateData3("Athuorized!    " + data[0].symbol);
            console.log(privateData2)

          } catch (error) {
            //localStorage.removeItem("authToken");                     //This is done in auth context.  localStorage.removeItem("authToken");   or clear?
            setError("You are not authorized please login");
          }
        };
         fetchPrivateData3()
          
        }, []); 
 



        const handleDateChange = (date) => {
          console.log(date); // You can perform actions when a date is selected
        };
      
        // Customize the appearance of each date cell
        const renderDay = (day, selectedDate, isInCurrentMonth, dayComponent) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
                height: '100px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: isInCurrentMonth ? 'white' : '#f5f5f5',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
              onClick={() => handleDateChange(day)}
            >
              <Typography variant="body1">{dayComponent}</Typography>
            </Box>
          );
        };




        



    return (
      <>


          <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '50px' }}>
      </Container>

 

       </>
    )
    
};

export default Calendar