import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const Privacy = () => {
  return (
    <Container maxWidth="md">
      <br />
      <br />
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        Your Privacy Policy
      </Typography>
      <br />
        <Typography variant="body1">
          **Privacy Policy for ChartingFS**

          **Effective Date: 2023-08-30**

          **Introduction**

          Welcome to chartingfs@gmail.com (the "Service"). This Privacy Policy is designed to inform users ("User," "You," or "Your") about how we collect, use, and protect your personal information when you use our Service. By accessing or using our Service, you consent to the practices described in this Privacy Policy.
          1. Information We Collect
          1.1. Personal Information: We may collect personal information, including but not limited to your name, email address, billing information, and other data you provide when you sign up for our Service.

          1.2. Usage Information: We may collect information about how you use the Service, such as your IP address, device type, browser type, pages visited, and actions taken within the Service.
          2. How We Use Your Information

          2.1. To Provide and Improve Our Service: We use your information to provide, maintain, and improve the Service, including user accounts, customer support, and analytics.

          2.2. To Send Updates: We may send you updates, newsletters, or promotional emails about our Service. You can opt out of these communications at any time.
          3. Information Sharing

          We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers who assist us in delivering the Service. These providers are bound by confidentiality agreements and only use your information for authorized purposes.

          4. Data Security

          We implement reasonable security measures to protect your data. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

          5. Cookies and Tracking Technologies

          We use cookies and similar tracking technologies to improve your experience on our Service. You can adjust your browser settings to block or limit these technologies.

          **6. Your Rights**

          You have the right to access, correct, update, or delete your personal information. To exercise these rights or request information about your data, please contact us at chartingfs@gmail.com.

          7. Data Retention

          We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.

          8. Third-Party Links

          Our Service may contain links to third-party websites or services. This Privacy Policy does not apply to those external sites. We encourage you to review the privacy policies of those websites or services.

          9. Children's Privacy

          Our Service is not intended for children under the age of 18. We do not knowingly collect personal information from children under this age. If you are a parent or guardian and believe we have collected information from your child, please contact us to have it removed.

          10. International Users

          Your information may be transferred to and processed in countries other than your own. If you access our Service from outside the United States, please be aware that your data may be transferred to the United States or other countries.
          11. Changes to the Privacy Policy

          We may update or change this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this Privacy Policy periodically.

          **12. Contact Information**

          If you have questions or concerns about this Privacy Policy, please contact us at chartingfs@gmail.com.

          13. User Consent

          By using our Service, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.
        </Typography>
   
      <br />

    </Container>
  );
};

export default Privacy;