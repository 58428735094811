import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React,  { Component }  from 'react';
import { useState } from 'react';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
// mock
import Divider from "@mui/material/Divider";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import useResponsive from '../hooks/useResponsive';
import CallToActionIcon from '@mui/icons-material/CallToAction';
// components
import DownloadIcon from '@mui/icons-material/Download';
import logo from "../../images/sampleLogo.png"
import Scrollbar from '../scrollbar';
import useAxios from '../../api/useAxiosPrivate'
import BusinessIcon from '@mui/icons-material/Business';
import { ListItemIcon, ListItemButton } from '@mui/material';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import GppGoodIcon from '@mui/icons-material/GppGood';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FavoriteIcon from '@mui/icons-material/Favorite';
/*
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' ,"backgroundColor":"#FFFFFF"}}>
        LOGO
      </Box>
*/
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);



  const [premStatus, setPremStatus] = useState();
  const axiosPrivate = useAxios();

  /*useEffect(() => {

    const fetchPrivateData = async () => {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {

        const { data } = await axiosPrivate.get("/api/auth/authorize" , config);   
        console.log(data)
        setPremStatus(data.status)
      } catch (error) {

        setPremStatus(false)
      }

    };


    async function myFlow(){                                       
      await fetchPrivateData();  
    }
     myFlow()
      console.log(premStatus)
    }, []); */



    




/*

*/

//      <img src={logo} width={280} height={100} alt="logo" style={{ marginLeft: "0%", border: "none"}} />



  const renderContent = (
    <>
      <Scrollbar>

      <br></br><br></br><br></br>
      <Button
        component={Link}
        to="/"
        sx={{
          color: "#FFFFFF",
          fontFamily: "Roboto Mono",
          textTransform: "none",
          fontSize: "30px",
          fontStyle: "none",
          fontWeight: "bold",
          marginLeft: "30px",
          '&:hover': {
            backgroundColor: 'transparent', // Remove background color on hover
            textDecoration: 'none', // Remove underline on hover
          },
        }}
      >
        ChartingFS
      </Button>
      <Typography  style={{ color: "#FFFFFF", "fontFamily": "Roboto Mono",textTransform:"none",fontSize:"20px",fontStyle:"none", marginLeft:" 110px"}}>Beta</Typography>
     
      <br></br><br></br><br></br>
        <Divider />
        <Divider />
        <Divider />
        

          <br></br><br></br>
          <Typography component={Link} to="/companylist" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <BusinessIcon />
            </ListItemIcon>
            Companies
          </Typography>
          <br></br><br></br>
          {/*<Typography component={Link} to="/crypto" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <CurrencyBitcoinIcon />
            </ListItemIcon>
            Crypto
          </Typography>
          <br></br><br></br>*/}
   
          <Typography component={Link} to="/compare" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <CompareArrowsIcon />
            </ListItemIcon>
            Compare
          </Typography>       
          <br></br><br></br>   
          <Typography component={Link} to="/calendar" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <DateRangeIcon />
            </ListItemIcon>
            Calendar
          </Typography>       
        <br></br><br></br>

        <Typography component={Link} to="/favorites" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <FavoriteIcon />
            </ListItemIcon>
            Favorites
          </Typography>    
          <br></br><br></br>  

        <Typography component={Link} to="/youraccount" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <AccountBoxIcon />
            </ListItemIcon>
            Account
          </Typography>    
          <br></br><br></br> 
          <Typography component={Link} to="/pricing" style={{ color: '#FFFFFF', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            <ListItemIcon style={{ color: '#808080', marginRight: '-25px' }}>
              <GppGoodIcon />
            </ListItemIcon>
            Pricing
          </Typography>

 
          
        <br></br><br></br>
        <Divider />
        <Divider />
        <Divider />
        <br></br><br></br><br></br>
          {(premStatus === false)  ? (  //if logged in, and if premium is there. User.premium can only exist if user exist
          <div>
            <Typography  style={{ color: "#FFFFFF", "fontFamily": "Roboto Mono",textTransform:"none",fontSize:"40px",fontStyle:"none",fontWeight: "bold", marginLeft: "18%"}}>PREMIUM</Typography>
          </div>
          ) : (
            <div></div>
          )}  
        <br/>

        <br></br><br></br>
        <Divider />
        <Divider />
        <Divider />
        <br></br><br></br><br></br><br></br><br></br>
  
          <Typography component={Link} to="/disclaimer" style={{  color: 'rgba(255, 255, 255, 0.4)', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '12px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            Disclaimer
          </Typography>       
          <br></br><br></br>   
          <Typography component={Link} to="/privacy" style={{  color: 'rgba(255, 255, 255, 0.4)', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '12px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            Privacy Policy
          </Typography> 
          <br></br><br></br>   
          <Typography component={Link} to="/terms&conditions" style={{ color: 'rgba(255, 255, 255, 0.4)', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '12px', fontWeight: 'bold', marginLeft: '20%', border: 'none', }}>
            Terms & Conditions
          </Typography> 

      <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>


      </>
  );

  return (



    <Box 
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              "backgroundColor":"#333333"
            },
          }}
        >
        {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH ,"backgroundColor":"#333333"},
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>

  );
}
