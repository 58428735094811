const optionTemplate = {













  
  layout: {
    padding: {
      top: 0,    // Adjust as needed
      bottom: 0, // Adjust as needed
      right:10,

      //orignal below
      /*top: 0,    // Adjust as needed
      bottom: 0, // Adjust as needed
      right:0,*/
    },

  },
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 0,
    },
    line: {
      borderWidth: 3, // Adjust the line width as needed
      borderColor: 'blue', // Set the line color to blue (change to your desired color)
    },
    point: {
      radius: 3, // Set point radius to 0
      //hoverRadius: 500, // Set hover point radius to 0
    },
    
  },
  responsive: true,
  //maintainAspectRatio: true, // Disable maintaining aspect ratio
  maintainAspectRatio: false, // Override the global maintainAspectRatio setting
  //height: "1px",
  //aspectRatio: 3, // Set the canvas aspect ratio (width / height)
  //onResize: (chart, newSize) => {
    // Custom code to execute on resize
    //console.log('Chart resized to:', newSize);
  //},
  //resizeDelay: 200, // Delay the resize update by 200 milliseconds
 





  plugins: {


    tooltip: {
      titleFont: {
        family: 'Roboto Mono', // Set the font family for the title
        //size: 10, // Adjust the font size for the title as needed
        weight: 'bold', // Set the font weight for the title
        color: 'black', // Set the font color for the title
      },
      bodyFont: {
        family: 'Roboto Mono', // Set the font family for the body
        //size: 5, // Adjust the font size for the body as needed
        weight: 'normal', // Set the font weight for the body
        color: 'black', // Set the font color for the body
      },
      enabled: true,
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0; // Handle empty values as 0
      
          // Check if the y-axis represents percentages
          if (context.chart.scales.y && context.chart.scales.y.ticks) {
            const axisTicks = context.chart.scales.y.ticks;
            if (axisTicks[0].label.includes('%')) {
              return `${label}: ${(value * 100).toFixed(2)}%`;
            }
          }
      
          return `${label}: ${value}`;
        },
        afterLabel: (context) => {
          const chartType = context.chart.config.type;
          if (chartType === 'bar' && context.chart.options.scales.y.stacked) { // Check if the chart type is 'bar' and stacked
            const total = context.chart.data.datasets
              .map((dataset) => (dataset.data[context.dataIndex] || 0)) // Handle empty values as 0
              .reduce((acc, val) => acc + val, 0);
            return `Total: ${total}`;
          }
          return '';
        },
      },
    },
  
  

    
    legend: {
      position: "top",
      
      display: true,
      labels: {
        font: {
          family: 'Roboto Mono',
          size: 12,
          color: "black",
        },
        
      },
      
    },
    title: {
      display: true,
      text: "",
      font: {
        family: "Roboto Mono", // Set the font family to Roboto Mono
        size: 20,
        weight: "bold",
      },
      color: "black", // Explicitly set the color here
    },
  },
  scales: {
    x: {
      stacked: false,
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: "Roboto Mono", // Set the font family for x-axis labels
        },
        //maxTicksLimit: 10
        //stepSize: 5000000000, // Adjust as needed
      },
    },
    y: {
      grid: {
        display: true,
      },
      title: {
        display: true,
        text: "",
        font: {
          weight: "bold",
          size: 15,
          family: "Roboto Mono", // Set the font family for y-axis title
          color: "black",
        },
      },
      ticks: {
        callback: (value) => {
          const absValue = Math.abs(value);
          if (absValue >= 1e12) {
            return '$ ' + Math.round(value / 1e12) + 'T';
          } else if (absValue >= 1e9) {
            return '$ ' + Math.round(value / 1e9) + 'B';
          } else if (absValue >= 1e6) {
            return '$ ' + Math.round(value / 1e6) + 'M';
          } else if (absValue < 5) {
            return '$ ' + value.toFixed(2);
          } else {
            return '$ ' + Math.round(value);
          }
        },
        font: {
          family: "Roboto Mono", // Set the font family for y-axis ticks
        },
      },
      stacked: false,
      display: true,
    },
  },
};

export default optionTemplate;
