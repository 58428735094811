export default function abbreviateNumber(number) {
    const abbreviations = ['', 'K', 'M', 'B', 'T'];
    if (number < 1) {
      // Handle numbers less than 1
      const decimalPlaces = Math.min(3, Math.max(0, 3 - Math.floor(Math.log10(Math.abs(number)))));
      const roundedNumber = number.toFixed(decimalPlaces);
      return `${roundedNumber}${abbreviations[0]}`;
    } else {
  
        const abbreviations = ['', 'K', 'M', 'B', 'T'];
        const digits = Math.floor(Math.log10(number)) + 1;
        const abbreviationIndex = Math.floor((digits - 1) / 3);
        const abbreviatedNumber = number / Math.pow(10, abbreviationIndex * 3);
        const roundedNumber = Math.round(abbreviatedNumber * 100) / 100;
        return `${roundedNumber}${abbreviations[abbreviationIndex]}`;
      
    }
  }

  