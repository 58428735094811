import React, { useState, useEffect, useContext } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import {Button, Typography, Container} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import GradeIcon from '@mui/icons-material/Grade';
import us_symbols from "../utils/us_symbols.json"
import { TextField, } from '@mui/material';

//import CircularProgress from "@material-ui/core/CircularProgress";


import { API } from '../api';

import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';


import CircularProgress from '@mui/material/CircularProgress';
/*import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from '@mui/material/TableRow';*/

//import CircularProgress from "@material-ui/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import axios from "axios"
import Box from '@mui/material/Box';
import useAxios from '../api/useAxiosPrivate';
import { useNavigate } from "react-router-dom";

const CompanyList = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxios();  
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)
  const [error, setError] = useState("");
  const [text, setText] = useState('');
  const [count, setCount] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [companyListTest, setCompanyListTest] = useState([])
  const [favList, setFavList] = useState([]);
  const [activeStocks, setActiveStocks] = useState([])
  const [gainerStocks, setGainerStocks] = useState([])
  const [loserStocks, setLoserStocks] = useState([])
  const isSmallScreenLabel = useMediaQuery('(max-width:600px)');
  const isSmallScreen = useMediaQuery('(max-width: 1560px)');
  const isMiniScreen = useMediaQuery('(max-width: 1000px)');
  //const API = axios.create({ baseURL: 'https://app.chartingfs.com/'})

  const [premStatus, setPremStatus] = useState(null);







  const abbreviateNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + "T";
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + "M";
    } else {
      return number.toFixed(2);
    }
  };
 

  const searchList = [
    /*"AAPL",
    "MSFT",
    "AMZN",
    "GOOGL",
    "GOOG",
    "META",
    "TSLA",
    "UBER",*/
  ]


const companyList = [
  "AAPL",
  "MSFT",
  "AMZN",
  "GOOGL",
  "GOOG",
  "META",
  "TSLA",
  "UBER",
  "NFLX",
  "NVDA",
  "PYPL",
  "JPM",
  "JNJ",
  "V",
  "MA",
  "HD",
  "DIS",
  "BABA",
  "WMT",
  "PG",
  "UNH",
  "BAC",
  "VZ",
  "CMCSA",
  "INTC"
]
  

useEffect(() => {
  const fetchPrivateData = async () => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axiosPrivate.get("/api/auth/authorize", config);   
      setPremStatus(data.status);
    } catch (error) {
      setPremStatus(false);
    }
  };

  fetchPrivateData();
}, []); 



const toggleFavorite = async (favorite) => {
      if(favorite === ""){
        return
    }

    /*if(premStatus === false){
      navigate("/pricing");
      return
    }*/


    if(!user){
      navigate("/login");
      return
    }
  favorite = favorite.toUpperCase()
const config = {
  header: {
    "Content-Type": "application/json",
  },
};
let id = favorite

try {

  const {data} = await API.post("/api/data/quote", { id }, config, { withCredentials: true })

  if(data.length === 0){

  }
  else{
      await axiosPrivate.post("/api/data/favorite", {favorite}, config, { withCredentials: true });  
      setCount(count + 1); 
  }



  setText("")
} catch (error) {

  console.log(error)
  setText("")
}
};

useEffect(() => {
  const fetchPrivateData = async () => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axiosPrivate.get("/api/data/favlist", config, { withCredentials: true });   
      //console.log(data);
      setFavList(data);
      
      //console.log(data.status); // Log the updated premStatus here
    } catch (error) {
      setFavList("error");
      console.log(error)
    }
  };

  fetchPrivateData();
}, [count]); 




   useEffect(() => {
      const  quoteGetter = async () => {
        const config = {
          header: {
            "Content-Type": "application/json",
          },
        };


        try {


          const fetchRequests = companyList.map((id) =>
          API.post("/api/data/quote", { id }, config, { withCredentials: true })
        );

        const responses = await Promise.all(fetchRequests);
  
        const listData = responses.map((response) => response.data);
        const flattenedListData = listData.flat();
          setCompanyData(flattenedListData)

          //setCompanyName(quote[0].name)
          //setCompanyStockPrice(quote[0].price)
          //setCompanyMarketCap(quote[0].marketCap)

        setIsLoading(false);
    
        } catch (error) {
          setIsLoading(false);
          console.log(error)
          setError(error);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      };
      quoteGetter()


   
      }, []); 





 
      useEffect(() => {
        const  ActiveGetter = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
  

          try {
            const response  = await API.get("/api/data/active", config, { withCredentials: true })
            const response1  = await API.get("/api/data/gainers", config, { withCredentials: true })
            const response2  = await API.get("/api/data/losers", config, { withCredentials: true })
            const activeStocks = response.data
            const gainerStocks = response1.data
            const loserStocks = response2.data
            setActiveStocks(activeStocks)
            setGainerStocks(gainerStocks)
            setLoserStocks(loserStocks)


        

  
          setIsLoading2(false);
      
          } catch (error) {
            setIsLoading2(false);
            setError(error);
          }
        };
        ActiveGetter()
  
  
     
        }, []); 








      /*const handleSearch = (event, value) => {
        const selectedValue = value || event.target.value;
        setSearchTerm(selectedValue);
      };

      const handleAutocompleteChange = (event, value) => {
        if (value) {
          const selectedTicker = value.toUpperCase();
          // Check if the selected value is in the companyList
          if (companyList.includes(selectedTicker)) {
            // Value is found in the companyList, navigate to the company page
            window.location.href = `/company/${selectedTicker}`;
          } else {
            // Value is not found in the companyList, do nothing or display an error message
            // For now, let's just do nothing, should do nothing
            window.location.href = `/company/${selectedTicker}`;
          }
        }
      };*/



      const trimmedSearchTerm = searchTerm ? searchTerm.trim() : '';

      const optionAmount = trimmedSearchTerm.length > 0
      ? searchList.filter((option) =>
          option.toLowerCase().includes(trimmedSearchTerm.toLowerCase())
        ).slice(0, 3) // Limit to 3 options
      : [];







      const handleSearch = (symbol) => {
        setText("")
        if(symbol === ""){
            return
        }
        //event.preventDefault();
        const url = `/company/${symbol}`;
        navigate(url);


      };


          // USE MOST GAINER AND BIGGEST LOSERS??  or active stock


          useEffect(() => {
            // Add a click event listener to the clear button
            const close = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (close) {
              close.addEventListener('click', () => {
                // Handle clearing the input, e.g., setText('')
                setText('');
              });
            }
          }, []);

          const newSymbolsWithStyling = us_symbols.map((item) => ({
            ...item,
            labelWithGreyLastWord: (
              <div>
                <span style={{  fontSize: isSmallScreen ? '13px' : '16px' }}>
                  {item.label}
                </span>{' '}
                <span style={{ color: 'grey', fontSize: isSmallScreen ? '10px' : '12px' }}>{item.symbol}</span>
              </div>
            ),
          }));




      return (
        <div>
              {/*<Container maxWidth="100%" sx={{ textAlign: 'center', marginTop: '30px',  }}>*/}
              <Container maxWidth="100%" sx={{ textAlign: 'center', marginTop: '30px',}}>
        
              <Typography           sx={{
            fontFamily: 'Roboto Mono', // Change font for the label
            fontWeight: 'bold', // Make the label bold
            fontSize: isSmallScreen ? '15px' : '25px', // Adjust font size based on screen size\
          }} >Popular Companies</Typography>
          <br></br>
          <Autocomplete
        freeSolo
        options={text === "" ? [] : newSymbolsWithStyling}
        value={text}
        noOptionsText={<span style={{   fontSize: isSmallScreenLabel ? '13px' : '16px'  }}>Start Searching </span>}
        filterOptions={(options, { inputValue }) => {
          let count = 0;
          const filteredOptions = options.filter((option) => {
          const matches =
            option.label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
            option.symbol.toLowerCase().startsWith(inputValue.toLowerCase());
      
          // If the option matches the filter criteria and the count is less than 50, include it
          if (matches && count < 100) {
            count++;
            return true;
          }
          return false;
        });
        // Sort the filtered options by their index in the original data source
        return filteredOptions.sort((a, b) => {
          const indexA = newSymbolsWithStyling.findIndex(
            (item) => item.label === a.label && item.symbol === a.symbol
          );
          const indexB = newSymbolsWithStyling.findIndex(
            (item) => item.label === b.label && item.symbol === b.symbol
          );
          return indexA - indexB;
        });
      }}
      onBlur={() => {
        if (text !== '') {
          // Clear the text when the user clicks off the text field
          setText('');
        }
      }}
      renderOption={(props, option) => (

        <li
        {...props}
        onClick={() => handleSearch(option.symbol)} // Call handleSearch when the option is clicked
      >
        {option.labelWithGreyLastWord}
      </li>

    )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <Box display="flex" alignItems="center" style={{ fontFamily: 'Roboto Mono', fontSize: isMiniScreen ? '12px' : '16px' }}>
                <SearchIcon style={{ marginRight: '8px' }} /> {/* Search icon */}
                Search 50,000+ Tickers (e.g., 'AAPL')
              </Box>
            }
            //autoComplete='false'
            variant="outlined"
            //value={searchTerm}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevent form submission
                handleSearch(text) // Use onClick to trigger handleSubmit
                //toggleFavorite(text, true);
              }
            }}
            style={{
              flex: 1,
              maxWidth: isSmallScreen ? '900px' : '900px', // Set the width dynamically based on screen size
              backgroundColor: '#f0f0f0',
              fontFamily: 'Roboto Mono',
              minWidth: isMiniScreen ? '300px' : '900px', // Adjust the minimum width for small screens
            }}
          />
        )}
        PaperComponent={({ children }) => (
          <Paper sx={{ fontFamily: 'Roboto Mono', color: 'black', }}>{children}</Paper>
        )}
      />



<br></br>   <br></br>
        <div style={{ display: 'flex', justifyContent: 'center', marginRight: '0px' }}>
          <div style={ {marginRight: isSmallScreen ? '-15px' : '30px', }}>



               




            {/*LEFT SECTION */}
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper}   style={{
                fontFamily: 'Roboto Mono',
                backgroundColor: '#F9F9F9',
                border: 'none',
                boxShadow: 'none', // Remove the box shadow
                marginRight: '100px',
              }}>
                <Table aria-label="company data table" style={{ fontFamily: 'Roboto Mono' }}>
                  <TableHead >
                    <TableRow>
                      <TableCell style={{ fontFamily: 'Roboto Mono' }}>Company</TableCell>
                      {!isSmallScreen ? (
                        <>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Price</TableCell>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Change</TableCell>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Market Cap</TableCell>
                      </>
                      ) : (
                        <></>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyData.map((data) => (
                      <TableRow key={data.symbol}>
                        <TableCell component="th" scope="row" style={{ fontFamily: 'Roboto Mono',  }}>
                          <Button component={Link} to={`/company/${data.symbol}`} style={{ color: 'black', textDecoration: 'none', fontSize: '14px', fontWeight: 'bold' }}>
                            {data.name}
                            </Button>
                            <span style={{ color: 'grey', fontSize: '12px'  }}>{data.symbol}</span>
                            {favList.includes(data.symbol) ? (
                            <GradeIcon onClick={() => toggleFavorite(data.symbol)} style={{cursor: 'pointer', color: 'red', fontSize: '20px', marginLeft: '20px',verticalAlign: 'sub' }} />
                            ) : (
                                <StarOutlineIcon onClick={() => toggleFavorite(data.symbol)}style={{ cursor: 'pointer', color: 'red', fontSize: '20px', marginLeft: '20px',verticalAlign: 'sub' }} />
                        )}
                         </TableCell>

                        {!isMiniScreen ? (
                          <>
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Roboto Mono', fontWeight: 'bold',
                          }}
                        >
                          {data.price} {/* Replace 'price' with the actual key for stock price in your data */}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: data.change < 0 ? 'red' : 'green',
                            fontFamily: 'Roboto Mono',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.change >= 0 ? `+${data.change.toFixed(2)}%` : `${data.change.toFixed(2)}%`}
                        </TableCell>
                        <TableCell align="right" style={{ fontFamily: 'Roboto Mono', fontWeight: 'bold',}}>
                          {abbreviateNumber(data.marketCap)}
                        </TableCell>
                        </>
                        ) : (
                         <></>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>











            {/*RIGHT SECTION */}
       
                      {/* Another Table */}
        {/* Add your second table here */}  



        
        {!isSmallScreen ? (
            isLoading2 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                {/*<CircularProgress />*/}
              </div>
            ) : (
          <TableContainer component={Paper} style={{ fontFamily: 'Roboto Mono', backgroundColor: '#F0F0F0', width: '500px', marginLeft: "40px", padding: '16px' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', fontFamily: 'Roboto Mono' }}>Top 10 Daily Active Stocks</Typography>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell  align="left" style={{ fontFamily: 'Roboto Mono' }}>Rank</TableCell>
                <TableCell style={{ fontFamily: 'Roboto Mono' }}>Name</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Price</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Map your top gainer stocks here */}
              {activeStocks.map((data, index) => (
                <TableRow key={data.symbol}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell  align="left" style={{fontFamily: 'Roboto Mono',}}>
                     {data.name} 
                     <span style={{ color: 'grey', fontSize: '10px', marginLeft: '5px' }}>{data.symbol}</span>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontFamily: 'Roboto Mono',
       
                    }}
                  >
                    {data.price} {/* Replace 'price' with the actual key for stock price in your data */}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: data.change < 0 ? 'red' : 'green',
                      fontFamily: 'Roboto Mono',
              
                    }}
                  >
                    {data.change >= 0 ? `+${data.change.toFixed(2)}%` : `${data.change.toFixed(2)}%`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

              <br></br><br></br><br></br>

          <Typography variant="h6" style={{ fontWeight: 'bold', fontFamily: 'Roboto Mono' }}>Top 10 Daily Gainer Stocks</Typography>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell  align="left" style={{ fontFamily: 'Roboto Mono' }}>Rank</TableCell>
                <TableCell style={{ fontFamily: 'Roboto Mono' }}>Name</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Price</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Map your top gainer stocks here */}
              {gainerStocks.map((data, index) => (
                <TableRow key={data.symbol}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell  align="left" style={{fontFamily: 'Roboto Mono',}}>
                     {data.name} 
                     <span style={{ color: 'grey', fontSize: '10px', marginLeft: '5px' }}>{data.symbol}</span>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontFamily: 'Roboto Mono',
       
                    }}
                  >
                    {data.price} {/* Replace 'price' with the actual key for stock price in your data */}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: data.change < 0 ? 'red' : 'green',
                      fontFamily: 'Roboto Mono',
              
                    }}
                  >
                    {data.change >= 0 ? `+${data.change.toFixed(2)}%` : `${data.change.toFixed(2)}%`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <br></br><br></br><br></br>

        <Typography variant="h6" style={{ fontWeight: 'bold', fontFamily: 'Roboto Mono' }}>Top 10 Daily Loser Stocks</Typography>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell  align="left" style={{ fontFamily: 'Roboto Mono' }}>Rank</TableCell>
              <TableCell style={{ fontFamily: 'Roboto Mono' }}>Name</TableCell>
              <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Price</TableCell>
              <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Change</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Map your top gainer stocks here */}
            {loserStocks.map((data, index) => (
              <TableRow key={data.symbol}>
                <TableCell>{index + 1}</TableCell>
                <TableCell  align="left" style={{fontFamily: 'Roboto Mono',}}>
                  {data.name} 
                  <span style={{ color: 'grey', fontSize: '10px', marginLeft: '5px' }}>{data.symbol}</span>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: 'Roboto Mono',

                  }}
                >
                  {data.price} {/* Replace 'price' with the actual key for stock price in your data */}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: data.change < 0 ? 'red' : 'green',
                    fontFamily: 'Roboto Mono',
            
                  }}
                >
                  {data.change >= 0 ? `+${data.change.toFixed(2)}%` : `${data.change.toFixed(2)}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        </TableContainer>
          )
          ) : (
            <></>
          )}
        









          
        </div>
        </Container>
        </div>

      );
    };
    
    export default CompanyList;
    