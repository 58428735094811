
import React, { useState, useEffect, useRef, useCallback , useContext} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Container from '@mui/material/Container';
import dataTemplate from '../company/CompanyGraphTemplates/dataTemplate';
import optionTemplate from '../company/CompanyGraphTemplates/optionTemplate'
import plugins from "../company/CompanyGraphTemplates/pluginsTemplate"
import { Chart } from 'chart.js';
import { time } from 'chart.js';
import { Button, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, IconButton, Box, Typography, Grid, AppBar, Toolbar, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
//import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from '../api';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import useAxios from '../api/useAxiosPrivate';
import { Bar, Line } from "react-chartjs-2";
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import us_symbols from "../utils/us_symbols.json"
const Compare = () => {

  const location = useLocation();
  //const metric = location.state && location.state.metric;
  //const id =  location.state.id;
 
  const id = location.state && location.state.id;
  const metric = location.state && location.state.metric
  
  //console.log(metric)
  //console.log(id)
  const isSmallScreenLabel = useMediaQuery('(max-width:600px)');

  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const isMediumScreen = useMediaQuery('(max-width:1700px)');
  const isMiniScreen = useMediaQuery('(max-width:500px)');
  //const {id} = useParams();
  const [error, setError] = useState("");
  const colors = ['#CF4123', '#598F90', "#906059", "#905980", "#59906F", "#7C906F", "#5252AA", "#4383CC", "#1ABC9C"];
  const [selectedRange, setSelectedRange] = useState('YTD');


  const [text, setText] = useState('');
  const [graphsxAxis, setGraphsxAxis] = useState([]);
  let initialOption = ""
  let initialID = []
  if(metric !== null){
    initialOption = metric
  }
  else{
    initialOption = "Revenue"
  }
  const [selectedOption, setSelectedOption] = useState(initialOption);
  if(id !== null){
    initialID = [id]
  }
  else{
    initialID = ["AAPL", "TSLA"]
  }
  const [companies, setCompanies] = useState(initialID);




  const refGraph = useRef(null);
  const [isStacked, setIsStacked] = useState(false);
  //const [graphLabels, setGraphLabels] = useState([metric, "APPL"]);
  const [graphData, setGraphData] = useState([]);
  const [graph, setGraph] = useState();
  const dataTemplateGraph = cloneDeep(dataTemplate);
  const optionTemplateGraph = cloneDeep(optionTemplate);




    //const frequency = "quarterly"
    const [frequency, setFrequency] = useState("quarterly");



//console.log(companies)


  const stackedGraph = () => {
    if(isStacked === false) {
      setIsStacked(true)
      optionTemplateGraph.scales.y.stacked =  true
      optionTemplateGraph.scales.x.stacked =  true
    }
    else if( isStacked === true){
      setIsStacked(false)
      optionTemplateGraph.scales.y.stacked =  false
      optionTemplateGraph.scales.x.stacked =  false
    }
  };


  function padArrayWithNull(arr, length) {
    if (arr.length >= length) {
      return arr; // No need to pad if the array is already longer or equal to the desired length
    }
  
    const padding = Array(length - arr.length).fill(null); // Create an array of null values for padding
    return [...padding, ...arr]; // Combine the padding array and the original array
  }



  const fetchCompanyData = async (companyId, selectedOption) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let paramResponse1 = ""
    let paramResponse2 = ""
    if(selectedOption === "Revenue"){
      paramResponse1 = "FS"
      paramResponse2 = "revenue"
    }
    if(selectedOption === "Gross Profit"){
      paramResponse1 = "FS"
      paramResponse2 = "grossProfit"
    }
    if(selectedOption === "Operating Income"){
      paramResponse1 = "FS"
      paramResponse2 = "operatingIncome"
    }
    if(selectedOption === "Operating Expenses"){
      paramResponse1 = "FS"
      paramResponse2 = "operatingExpenses"
    }
    if(selectedOption === "Net Income"){
      paramResponse1 = "FS"
      paramResponse2 = "netIncome"
    }
    if(selectedOption === "Cash"){
      paramResponse1 = "cashflow"
      paramResponse2 = "cashAtEndOfPeriod"
    }
    if(selectedOption === "Gross Margin"){
      paramResponse1 = "margins"
      paramResponse2 = "grossProfitMargin"
    }
    if(selectedOption === "Operating Margin"){
      paramResponse1 = "margins"
      paramResponse2 = "operatingProfitMargin"
    }
    if(selectedOption === "Profit Margin"){
      paramResponse1 = "margins"
      paramResponse2 = "netProfitMargin"
    }
    if(selectedOption === "OpEx Y/Y"){
      paramResponse1 = "growth"
      paramResponse2 = "growthRevenue"
    }
    if(selectedOption === "Revenue Y/Y"){
      paramResponse1 = "growth"
      paramResponse2 = "growthOperatingExpenses"
    }
    if(selectedOption === "Market Cap"){
      paramResponse1 = "marcap"
      paramResponse2 = "marketCapitalization"
    }
    if(selectedOption === "Outstanding Shares"){
      paramResponse1 = "marcap"
      paramResponse2 = "marketCapitalization"
    }

   

    let sliced = 16;
    if (frequency === "annual"){
      sliced = 12
    }
    else if ( frequency === "quarterly"){
      sliced = 16
    }
    //console.log(paramResponse1)
    //console.log(paramResponse2)
    
    const [financialResponse] = await Promise.all([
      API.post(`/api/data/${paramResponse1}`, { id: companyId, frequency }, config, { withCredentials: true }),
    ]);

    const incomeStatements = financialResponse.data;
    const relevantIncomeStatements = incomeStatements.slice(0, sliced);
  

    const [datesResponse] = await Promise.all([
      API.post(`/api/data/FS`, { id: "MSFT", frequency }, config, { withCredentials: true }),        //is this a good fix? NO ---- BIG PROBLEMS test with uber, msft
      //API.post(`/api/data/FS`, { id: companyId, frequency }, config, { withCredentials: true }),   
    ]);

    const dateStatements = datesResponse.data;
    const relevantDateStatements = dateStatements.slice(0, sliced);
    //console.log(selectedOption)
    return {
      creatorGraphNet:
      selectedOption === "Outstanding Shares"
      ? padArrayWithNull(relevantIncomeStatements.map((element) => (element[paramResponse2] / element.stockPrice)).reverse(), sliced) //actually a decent fix, only thing to consider is values that end early, not start early
      : padArrayWithNull(relevantIncomeStatements.map((element) => element[paramResponse2]).reverse(),sliced),  //[4,5,6,7,8]  fixed length = sliced -> [null,null,null,4,5,6,7,8]
        //? relevantIncomeStatements.map((element) => (element[paramResponse2] / element.stockPrice)).reverse()
       // : relevantIncomeStatements.map((element) => element[paramResponse2]).reverse(),  
      graphsxAxis:  relevantDateStatements.map((element) => {
        if (frequency === "annual") {
          return element.calendarYear;
        } else if (frequency === "quarterly") {
          return element.calendarYear.substring(2) + " " + element.period;
        }
        return null;
      }).reverse(),
    };
  };
  

  useEffect(() => {
    const fetchAllCompanyData = async () => {
      try {
        const data = await Promise.all(companies.map((company) => fetchCompanyData(company, selectedOption)));
        const creatorGraphNet = data.map((companyData) => companyData.creatorGraphNet);
        const graphsxAxis = data[0].graphsxAxis; // Assuming all companies have the same x-axis
        //console.log(creatorGraphNet)
        setGraphData(creatorGraphNet);
        setGraphsxAxis(graphsxAxis);
        //console.log(creatorGraphNet);
      } catch (error) {
        setError(error);
        console.log(error)
      }
    };
  
    fetchAllCompanyData();
  }, [frequency, selectedOption]);





  useEffect(() => {
    
    dataTemplateGraph.datasets = [];
    dataTemplateGraph.labels = graphsxAxis;     
    //optionTemplateGraph.plugins.title.display =  "true"; 
    optionTemplateGraph.plugins.title.text =  selectedOption; 
    optionTemplateGraph.scales.y.stacked =  isStacked
    optionTemplateGraph.scales.x.stacked =  isStacked    
    //PERCENT GRAPHS (NOT ALL LINES)
    if(selectedOption === "Gross Margin" || selectedOption === "Operating Margin" || selectedOption === "Profit Margin" || selectedOption === "Revenue Y/Y" || selectedOption === "OpEx Y/Y"){

      optionTemplateGraph.scales.y.ticks = {
        callback: (value) => {
          return Math.round(value * 100) + '%'; // Round to the nearest whole number
        },
      }
    }
    




    for(let i = 0; i < graphData.length; i ++){
      dataTemplateGraph.datasets.push(
      {
        label: companies[i], 
        data: graphData[i],    
        backgroundColor: colors[i],
        borderColor: colors[i],
      })
    }
    //LINE GRAPHS
    if(selectedOption === "Gross Margin" || selectedOption === "Operating Margin" || selectedOption === "Profit Margin" || selectedOption === "Revenue Y/Y" || selectedOption === "OpEx Y/Y"|| selectedOption === "Market Cap"|| selectedOption === "Outstanding Shares" ){
      optionTemplateGraph.scales.y.stacked =  false //disabling line stacks
      optionTemplateGraph.scales.x.stacked =  false    
      setGraph(<Line   plugins = {plugins} ref ={refGraph} data={dataTemplateGraph}  options={optionTemplateGraph}></Line>); 
    }
    else{
      setGraph(<Bar   plugins = {plugins} ref ={refGraph} data={dataTemplateGraph}  options={optionTemplateGraph}></Bar>);       //maybe put inside useEffect? depends on render issues. On the outside it loads empty graph then adds
    }
 
  }, [graphData, isStacked]);


  console.log(graphsxAxis)
  console.log(graphData)


  const handleFrequency = (value) => {
    // Remove the selected company from the companies array
    setFrequency(value)
  };


  const handleDelete = (company) => {
    // Remove the selected company from the companies array
    const removedIndex = companies.findIndex((c) => c === company);
    const updatedCompanies = companies.filter((c) => c !== company);
    setCompanies(updatedCompanies);
    const updatedGraphData = graphData.filter((item, index) => index !== removedIndex);
    setGraphData(updatedGraphData);
  };


  const handleAdd = async (company) => {
    // Check if the company is already in the companies array
    setText("")
    company = company.toUpperCase();
    if (!companies.includes(company) && company !== "" && companies.length < 10) {
      // Add the new company to the companies array
      const updatedCompanies = [...companies, company];
      setCompanies(updatedCompanies);
  
      try {
        // Fetch and add data for the new company to graphData
        const companyData = await fetchCompanyData(company, selectedOption);  
        setGraphData((prevData) => [...prevData, companyData.creatorGraphNet]);                       //this is the glitch
      
      } catch (error) {
        setError(error);
        console.log(error)
      }
  
      //setText('');
    }
  };


  /*
    const handleAdd = async (company) => {
    // Check if the company is already in the companies array


    company = company.toUpperCase();
    if (!companies.includes(company) && company !== "" && companies.length < 10) {
      // Add the new company to the companies array

  
      try {
        // Fetch and add data for the new company to graphData
        const companyData = await fetchCompanyData(company, selectedOption);  

        if(companyData.creatorGraphNet.length !== 0){
          const updatedCompanies = [...companies, company];
          setCompanies(updatedCompanies);
          setGraphData((prevData) => [...prevData, companyData.creatorGraphNet]);                       //this is the glitch
        }
        
      } catch (error) {
        setError(error);
        console.log(error)
      }
  
      setText('');
    }
  };
  */


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleReset = (event) => {
    setSelectedOption("Revenue");
    setIsStacked(false)
    setCompanies([])
    setGraphData([])
    setFrequency("quarterly")
  };


  const createDownloadImage = () => {
    
      const link = document.createElement("a");
      link.download = `${id}_compareGraph.png`;
      link.href = refGraph.current.toBase64Image();
      link.click();
    
  };
  

  useEffect(() => {
    // Add a click event listener to the clear button
    const close = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (close) {
      close.addEventListener('click', () => {
        // Handle clearing the input, e.g., setText('')
        setText('');
      });
    }
  }, []);

  const newSymbolsWithStyling = us_symbols.map((item) => ({
    ...item,
    labelWithGreyLastWord: (
      <div>
        <span style={{  fontSize: isSmallScreen ? '10px' : '14px' }}>
          {item.label}
        </span>{' '}
        <span style={{ color: 'grey', fontSize: isSmallScreen ? '8px' : '12px' }}>{item.symbol}</span>
      </div>
    ),
  }));





return (
  <div>




    <br></br>  <br></br>   
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  

   {/* <TextField
      label={
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Roboto Mono', // Change font for the label
            fontWeight: 'bold', // Make the label bold
            fontSize: isSmallScreen ? '12px' : '16px', // Adjust font size based on screen size\
          }}
        >
          Add Ticker...
        </Typography>
      }
      
      autoFocus
      autoComplete="off"
      size="small"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent form submission
          handleAdd(text);
        }
      }}
      sx={{
        
        "& fieldset": { border: 'none'  },
        marginLeft: '12%',
        background: '#E5E5E5',
        fontFamily: 'Roboto Mono',
        '& .MuiOutlinedInput-root': {
          borderBottom: '5px solid black',
          boxShadow: 'none',
        },
  }}
/>
*/} 

{ <Autocomplete
            //disablePortal
            freeSolo                  // TJIS DISABLES MUST BE A VALUE WARNING, THIS REMOVES NOOPTIONSTEXT
            options={text === "" ? [] : newSymbolsWithStyling}
              value={text}
              noOptionsText={<span style={{   fontSize: isSmallScreen ? '9px' : '14px'  }}>Start Searching </span>}
              sx={{ width: '200px', marginLeft:"12%"}} // Center horizontally
              filterOptions={(options, { inputValue }) => {
              let count = 0;
              const filteredOptions = options.filter((option) => {
              const matches =
                option.label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
                option.symbol.toLowerCase().startsWith(inputValue.toLowerCase());
          
              // If the option matches the filter criteria and the count is less than 50, include it
              if (matches && count < 100) {
                count++;
                return true;
              }
              return false;
            });
            // Sort the filtered options by their index in the original data source
            return filteredOptions.sort((a, b) => {
              const indexA = newSymbolsWithStyling.findIndex(
                (item) => item.label === a.label && item.symbol === a.symbol
              );
              const indexB = newSymbolsWithStyling.findIndex(
                (item) => item.label === b.label && item.symbol === b.symbol
              );
              return indexA - indexB;
            });
          }}
          onBlur={() => {
            if (text !== '') {
              // Clear the text when the user clicks off the text field
              setText('');
            }
          }}
          
          renderOption={(props, option) => (

            <li
            {...props}
            onClick={() => handleAdd(option.symbol)} // Call handleSearch when the option is clicked
          >
            {option.labelWithGreyLastWord}
          </li>

        )}

        renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Roboto Mono', // Change font for the label
                fontWeight: 'bold', // Make the label bold
                fontSize: isSmallScreen ? '11.5px' : '16px', // Adjust font size based on screen size\
              }}
            >
              Add Ticker...
            </Typography>
          }
          //autoFocus
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent form submission
              handleAdd(text) // Use onClick to trigger handleSubmit
              //toggleFavorite(text, true);
            }
          }}
          autoComplete="off"
          size="small"
          sx={{
        
            "& fieldset": { border: 'none'  },
            //marginLeft: '12%',
            background: '#E5E5E5',
            fontFamily: 'Roboto Mono',
            '& .MuiOutlinedInput-root': {
              borderBottom: '5px solid black',
              boxShadow: 'none',
            },
      }}
          
        />
      )}
      PaperComponent={({ children }) => (
        <Paper sx={{ fontFamily: 'Roboto Mono', color: 'black', }}>{children}</Paper>
      )}
    />

}



<Container justifycontent='space-between' alignitems='stretch' spacing={3}>
  <Box>
    {companies.map((company) => (
      <div key={company} style={{ display: 'inline-block', marginRight: '10px' }}>
        <Typography
          style={{
            padding: '8px 39px', // Increase padding to accommodate "x" button
            paddingLeft: '10px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            color: 'black',
            border: 'none',
            borderBottom: '4px solid black',
            position: 'relative', // To position the "x" button
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          variant="outlined"
          sx={{
            fontSize: isSmallScreen ? '10px' : '16px', // Adjust font size based on screen size
          }}
        >
          <span>{company}</span> {/* Place the company name within a span */}
          <IconButton
            style={{
              padding: '0',
              color: 'black',
              position: 'absolute',
              right: '4px', // Adjust this value for the desired spacing
            }}
            onClick={() => handleDelete(company)}
          >
            <CloseIcon  style={{fontSize: isSmallScreen ? '10px':'20px'  }} />
          </IconButton>
        </Typography>
      </div>
    ))}
  </Box>
</Container>


              

</div>









    <br></br><br></br>  <br></br> 
    <AppBar  elevation={0} style={{ height: '80px', background: '#F9F9F9' }} position="static">
      <Toolbar>
        <Grid container alignItems="center" justifyContent="center">


        <FormControl variant="outlined" sx={{fontFamily: 'Roboto Mono',width: isSmallScreen ? '85px':'220px' ,}}>
        <InputLabel sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px':'15px' }}>Marker</InputLabel>
        <Select
            size="small"
            label="Marker"
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{
              fontFamily: 'Roboto Mono',
              background: '#E5E5E5',
              fontWeight: 'bold',
              fontSize: isSmallScreen ? '8px' : '15px',
              wordSpacing: "-2px"
            }}
          >
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Revenue">Revenue</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Gross Profit">Gross Profit</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Income">Operating Income</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Expenses">Operating Expenses</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Net Income">Net Income</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Cash">Cash</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Gross Margin">Gross Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Operating Margin">Operating Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Profit Margin">Profit Margin</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Revenue Y/Y">Revenue Y/Y</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="OpEx Y/Y">OpEx Y/Y</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Market Cap">Market Cap</MenuItem>
      <MenuItem sx={{ fontFamily: 'Roboto Mono', fontSize: isSmallScreen ? '10px' : '15px' }} value="Outstanding Shares">Outstanding Shares</MenuItem>
        </Select>





      </FormControl>
          </Grid>


          <Grid container alignItems="center" justifyContent="center">
        <Typography
          onClick={() => handleFrequency('quarterly')}
          style={{
            display: 'inline-block',
            padding: isSmallScreen ? '4px 8px' : '8px 16px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            color: frequency === 'quarterly' ? 'black' : '#A0A0A0',
            textDecoration: frequency === 'quarterly' ? 'underline' : 'none',
            fontFamily: 'Roboto Mono',
            fontSize:  isSmallScreen ? "8px": "16px"
          }}
        >
          Quarterly
        </Typography>
        <Typography
          onClick={() => handleFrequency('annual')}
          style={{
            display: 'inline-block',
            padding: isSmallScreen ? '4px 8px' : '8px 16px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            color: frequency === 'annual' ? 'black' : '#A0A0A0',
            textDecoration: frequency === 'annual' ? 'underline' : 'none',
            fontFamily: 'Roboto Mono',
            fontSize:  isSmallScreen ? "8px": "16px"
          }}
        >
          Annual
        </Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Typography
          onClick={() => stackedGraph()}
          style={{
            display: 'inline-block',
            padding: isSmallScreen ? '4px 8px' : '8px 16px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            textDecoration: isStacked === true ? 'underline' : 'none',
            fontFamily: 'Roboto Mono',
            color: isStacked === true ? 'black' : '#A0A0A0',
            fontSize:  isSmallScreen ? "8px": "16px"
          }}
        >
          Stack
        </Typography>
        <Typography
          onClick={() => createDownloadImage()}
          style={{
            marginLeft: isSmallScreen ? '4px' : '6px',
            display: 'inline-block',
            padding: isSmallScreen ? '4px 6px' : '7px 10px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            textDecoration: 'none',
            fontFamily: 'Roboto Mono',
            color: 'black',
            fontSize:  isSmallScreen ? "5px": "16px"
          }}
        >
          <DownloadIcon sx={{ fontSize: isSmallScreen ? '10px' : '20px'}} />
        </Typography>
        <Typography
          onClick={() => handleReset()}
          style={{
            marginLeft: isSmallScreen ? '4px' : '6px',
            display: 'inline-block',
            padding: isSmallScreen ? '4px 6px' : '7px 10px',
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
            textDecoration: 'none',
            fontFamily: 'Roboto Mono',
            color: 'black',
            fontSize:  isSmallScreen ? "5px": "16px"
          }}
        >
          <DeleteIcon sx={{ fontSize: isSmallScreen ? '10px' : '20px'}} />
        </Typography>
      </Grid>
        </Toolbar>
        
    </AppBar>



   
    <Container justifycontent='space-between' alignitems='stretch' spacing={3} >
   
    </Container>
    <div style={{ marginLeft: "-30px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div
        style={{
          height: isSmallScreen ? '500px' : isMediumScreen ? '450px' : '600px', // Adjust the heights for small and larger screens
          width: '85%',
        }}
      >
        {graph}
      </div>
    </div>
  </div>
    );
};
    
export default Compare;
