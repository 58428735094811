import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <br />
      <br />
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        Terms & Conditions
      </Typography>
      <br />
        <Typography variant="body1">
        ChartingFS - Terms and Conditions

        **Effective Date: 2023-08-30**

Welcome to ChartingFS, a data visualization software platform. These Terms and Conditions govern your use of our service. By using ChartingFS, you agree to comply with these terms.

1. Account Creation:

1.1. You can create a ChartingFS user account by providing accurate information.
1.2. Usage without registration may limit access to certain features.
1.3. Safeguard your login credentials and choose strong passwords.

2. Account Termination:

2.1. You can terminate your ChartingFS account by contacting us directly.

3. Account Suspension and Deletion:

3.1. ChartingFS reserves the right to suspend or delete accounts for inappropriate, offensive, or terms-violating behavior.
3.2. Such actions do not lead to compensation or reimbursement.

4. Content Ownership:

4.1. ChartingFS or its licensors own all content unless otherwise stated.
4.2. Users must use content within the service's intended purpose.

5. Access to External Resources:

5.1. ChartingFS provides access to external resources by third parties; we're not responsible for their content or availability.

6. Acceptable Use:

6.1. Use ChartingFS within its intended purpose and in compliance with applicable laws.
6.2. ChartingFS may take measures to protect its interests against improper use.

7. Liability and Indemnification:

7.1. Limitations and indemnification clauses apply, with specific provisions for Australian and U.S. users.
7.2. Users may have non-excludable rights under applicable laws.

8. Service Interruption:

8.1. ChartingFS may interrupt or suspend the service for maintenance or changes.
8.2. Force majeure events may affect service availability.

9. Service Reselling:

9.1. Users may not reproduce, sell, or exploit any part of ChartingFS without our express permission.

10. Privacy Policy:

10.1. For personal data handling, refer to our Privacy Policy.

11. Intellectual Property Rights:

11.1. ChartingFS retains intellectual property rights related to the platform.

12. Changes to Terms:

12.1. ChartingFS may amend terms and will inform users of changes.
12.2. Continued use implies acceptance of revised terms.

13. Assignment of Contract:

13.1. ChartingFS may transfer rights or obligations.
13.2. Users cannot assign without written permission.

14. Contacts:

14.1. For communication related to ChartingFS, use provided contact information.

15. Severability:

15.1. Invalid or unenforceable provisions do not affect remaining terms.

16. Governing Law and Venue:

16.1. ChartingFS is governed by local law, and disputes are resolved in local courts.

17. Exception for European Consumers:

17.1. Certain provisions may vary for European Consumers based on applicable law.

These Terms and Conditions apply to your use of ChartingFS. Be sure to review the full document to understand your rights and responsibilities when using our data visualization software.
        </Typography>
   
      <br />

    </Container>
  );
};

export default TermsAndConditions;