import React from 'react';
import { Container, Typography } from '@mui/material';

const ComingSoon = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ color: '#ff6347' }}>
        &#x1F4C1;
      </Typography>
      <Typography variant="h5" sx={{ marginTop: '20px' }}>
        Feature Coming Soon
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '10px', color: '#666' }}>
        We're working hard to bring you this exciting new feature. Stay tuned!
      </Typography>
    </Container>
  );
};

export default ComingSoon;