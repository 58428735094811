
import React, { useState, useEffect, useRef, useCallback , useContext} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Container from '@mui/material/Container';
import dataTemplate from './CompanyGraphTemplates/dataTemplate';
import optionTemplate from './CompanyGraphTemplates/optionTemplate';
import plugins from "./CompanyGraphTemplates/pluginsTemplate"
import { Chart } from 'chart.js';
import { time } from 'chart.js';
import { TextField, Typography, Grid, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
//import CircularProgress from "@material-ui/core/CircularProgress";

import CircularProgress from '@mui/material/CircularProgress';

import { API } from '../api';

import moment from 'moment';
import useAxios from '../api/useAxiosPrivate';
import { Bar, Line } from "react-chartjs-2";
import { useParams } from 'react-router-dom'



const CompanyStock = () => {

  const {id} = useParams();
  const [error, setError] = useState("");
  const colors = ['#CF4123', '#598F90', "#906059", "#905980", "#59906F", "#7C906F", "#5252AA", "#4383CC", "#1ABC9C"];
  const [selectedRange, setSelectedRange] = useState('YTD');
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const isMediumScreen = useMediaQuery('(max-width:1700px)');
  const [isLoading, setIsLoading] = useState(true);


  const refGraphStock = useRef(null);
  const [isStackedStock, setIsStackedStock] = useState(false)
  const [graphLabelsStock, setGraphLabelsStock] = useState([]);
  const [graphDataStock, setGraphDataStock] = useState([]);
  const [graphStock, setGraphStock] = useState();
  const dataTemplateGraphStock= cloneDeep(dataTemplate);;
  const optionTemplateGraphStock = cloneDeep(optionTemplate);


  const [test, setTest] = useState();
  const [percentChange, setPercentageChange] = useState(0);

  const [dates, setDates] = useState([]);
  const [closePrices, setClosePrices] = useState([]);


  const [dates30min, setDates30min] = useState([]);
  const [closePrices30min, setClosePrices30min] = useState([]);

  
  const [dates15min, setDates15min] = useState([]);
  const [closePrices15min, setClosePrices15min] = useState([]);

  const dateRanges = ['ALL', '10Y', '5Y', '2Y', '1Y', 'YTD', '6M', '3M', "1M", '1W', "1D"];




  const abbreviateNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + "T";
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + "M";
    } else {
      return number.toFixed(2);
    }
  }


  const formatDate = (rawDate) => {
    const dateObject = moment(rawDate);
    const formattedDate = dateObject.format('MMM D, YYYY');
    return formattedDate;
  };


  const formatDateCustom = (rawDate) => {
    const dateObject = moment(rawDate);
    const formattedDate = dateObject.format('MMM D, h:mm A');
    return formattedDate;
  };


  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        const [stockResponse, stock30minResponse, stock15minResponse] = await Promise.all([
          API.post("/api/data/stock", { id }, config, { withCredentials: true }),         //this is daily values
          API.post("/api/data/stock30min", { id }, config, { withCredentials: true }),  
          API.post("/api/data/stock15min", { id }, config, { withCredentials: true }),    
        ]);
        
        const stock = (stockResponse.data).reverse();
        const stock30min = (stock30minResponse.data).reverse();
        const stock15min = (stock15minResponse.data).reverse();


        
        setDates(stock.map((dataPoint) => formatDate(dataPoint.date)));
        setClosePrices(stock.map((dataPoint) => dataPoint.close))

        setDates30min(stock30min.map((dataPoint) => formatDateCustom(dataPoint.date)))
        setClosePrices30min(stock30min.map((dataPoint) => dataPoint.open))

        setDates15min(stock15min.map((dataPoint) => formatDateCustom(dataPoint.date)))
        setClosePrices15min(stock15min.map((dataPoint) => dataPoint.open))   //open is what it should be

        setTest(stockResponse.data)
        setIsLoading(false)

  
      } catch (error) {
        setError(error);
      }
    };
  
    fetchFinancialData();
  }, []);



  useEffect(() => {
    dataTemplateGraphStock.datasets = [];
    // dataTemplateGraphStock.labels = graphsxAxis;                     
    //optionTemplateGraphStock.plugins.title.text = "Revenue Segments";
    optionTemplateGraphStock.plugins.legend.display = false;
    optionTemplateGraphStock.elements.line.borderWidth = 1.5;
  
    optionTemplateGraphStock.elements.point.radius = 0;
  
    optionTemplateGraphStock.scales.x.ticks.maxTicksLimit = 10;
    optionTemplateGraphStock.scales.x.ticks.autoSkip = true;
    //optionTemplateGraphStock.scales.x.ticks.maxRotation = 0;
    optionTemplateGraphStock.plugins.tooltip = {
      intersect: false, // Allow tooltips to appear even if not directly over the data point
      /*callbacks: {
        label: (context) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y;
          const date = dates[context.dataIndex];
          return `${label}: ${value} on ${date}`;
        },
      },*/
    };


    let filteredDates = [];
    let filteredClosePrices = [];
  
    if (selectedRange === 'ALL') {
      // No filtering required, use the original data
      filteredDates = dates;
      filteredClosePrices = closePrices;
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '10Y') {
      // Filter data for 10 years
      const tenYearsAgo = moment().subtract(10, 'years');
      filteredDates = dates.filter(date => moment(date, 'MMM D, YYYY').isSameOrAfter(tenYearsAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '5Y') {
      // Filter data for 5 years
      const fiveYearsAgo = moment().subtract(5, 'years');
      filteredDates = dates.filter(date => moment(date).isSameOrAfter(fiveYearsAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '2Y') {
      // Filter data for 2 years
      const twoYearsAgo = moment().subtract(2, 'years');
      filteredDates = dates.filter(date => moment(date).isSameOrAfter(twoYearsAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '1Y') {
      // Filter data for 1 year
      const oneYearAgo = moment().subtract(1, 'year');
      filteredDates = dates.filter(date => moment(date).isSameOrAfter(oneYearAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }

    } else if (selectedRange === 'YTD') {
      // Filter data for YTD
      const currentYear = moment().year();
      filteredDates = dates.filter(date => moment(date).year() === currentYear);
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }

    } else if (selectedRange === '6M') {
      // Filter data for 6 months
      const sixMonthsAgo = moment().subtract(6, 'months');
      filteredDates = dates.filter(date => moment(date).isSameOrAfter(sixMonthsAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }

    } else if (selectedRange === '3M') {
      // Filter data for 3 months
      const threeMonthsAgo = moment().subtract(3, 'months');
      filteredDates = dates.filter(date => moment(date).isSameOrAfter(threeMonthsAgo));
      filteredClosePrices = closePrices.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '1M') {
      // Filter data for 1 month
      const oneMonthAgo = moment().subtract(1, 'months');
      filteredDates = dates30min.filter(date => moment(date, 'MMM D, h:mm A').isSameOrAfter(oneMonthAgo));
      filteredClosePrices = closePrices30min.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '1W') {
      const oneWeekAgo = moment().subtract(1, 'week');
      filteredDates = dates30min.filter(date => moment(date, 'MMM D, h:mm A').isSameOrAfter(oneWeekAgo));
      filteredClosePrices = closePrices30min.slice(-filteredDates.length);
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    } else if (selectedRange === '1D') {
      // Filter data for 1 day (past 24 hours)
      const oneDayAgo = moment().subtract(1, 'day');
      filteredDates = dates15min.filter(date => moment(date, 'MMM D, h:mm A').isSameOrAfter(oneDayAgo));
      filteredClosePrices = closePrices15min.slice(-filteredDates.length);
    
      if (filteredClosePrices.length > 1) {
        const firstClose = filteredClosePrices[0];
        const lastClose = filteredClosePrices[filteredClosePrices.length - 1];
        const percentageChange = ((lastClose - firstClose) / firstClose) * 100;
        setPercentageChange(percentageChange);
      } else {
        setPercentageChange(0); 
      }
    }

    setGraphStock(
      <Line
        height={170}
        plugins={plugins}
        ref={refGraphStock}
        data={{
          labels: filteredDates,
          datasets: [
            {
              label: 'Price',
              data: filteredClosePrices,
              borderColor: 'green',
              fill: true,
              backgroundColor: 'rgba(0, 128, 0, 0.3)', // Green fill color
            },
          ],
        }}
        options={optionTemplateGraphStock}
      />
    ); // Maybe put inside useEffect? depends on render issues. On the outside, it loads an empty graph then adds
  }, [graphDataStock, closePrices, selectedRange]);








return (
  <div>
    <br></br>    <br></br>
<Grid container justifyContent="center" alignItems="center" spacing={2}>
  <Typography variant="h6" style={{ color: 'black', fontFamily: 'Roboto Mono' }}>
    {id.toUpperCase()}
  </Typography>
</Grid>
<br></br>
<Grid container justifyContent="center" alignItems="center" spacing={2}>
  <Typography variant="h6" style={{ color: percentChange < 0 ? 'red' : 'green', fontFamily: 'Roboto Mono' }}>
  {percentChange >= 0 ? `+${percentChange.toFixed(2)}%` : `${percentChange.toFixed(2)}%`}
  </Typography>
</Grid>

{isLoading  ? (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
    <CircularProgress style={{ width: '30px', height: '30px', marginRight:"10px" }}/>
  </div>
) : null}


  <br></br>
  <div style={{ textAlign: 'center' }}>
    {dateRanges.map((range) => (
      <Button
        key={range}
        onClick={() => setSelectedRange(range)}
        style={{
          color :"black",
          fontFamily: 'Roboto Mono',
          backgroundColor:"#F9F9F9",
          border:'none',
          margin: isSmallScreen ? '0px' : "0px",
          textDecoration: selectedRange === range ? 'underline' : 'none',
          fontWeight: selectedRange === range ? 'bold' : 'normal',
          fontSize: selectedRange === range ? '16px' : '14px', // Adjust the font size as needed
        }}
      >
        {range}
      </Button>
    ))}
  </div>


{/*
      <Button
        key={range}
        onClick={() => setSelectedRange(range)}
        style={{
          color :"black",
          fontFamily: 'Roboto Mono',
          margin: '1px',
          textDecoration: selectedRange === range ? 'underline' : 'none',
          fontWeight: selectedRange === range ? 'bold' : 'normal',
          fontSize: selectedRange === range ? '16px' : '14px', // Adjust the font size as needed
        }}
      >
        {range}
      </Button>

      */}

  




    <div style={{ marginLeft: "-30px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div
        style={{
          height: isSmallScreen ? '350px' : isMediumScreen ? '450px' : '700px', // Adjust the heights for small and larger screens
          width: '85%',
        }}
      >
        {graphStock}
      </div>
    </div>






  </div>
);
    };
    
  export default CompanyStock;
