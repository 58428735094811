



import ComingSoon from './notFound/ComingSoon';

import ScrollToTop from './utils/ScrollToTop';
import TermsAndConditions from "./Disclaimer/TermsAndConditions"
import Favorite from './favorite/favorite';
import { AuthProvider } from './context/AuthContext';
import CompanyInfo from './company/CompanyInfo';
import CompanyList from './company/CompanyList';
import DisclaimerPage from './Disclaimer/Disclaimer';
import Privacy from "./Disclaimer/Privacy"
//import DashboardLayout from './layouts/dashboard';
//import DashboardLayout from './layouts';
import Compare from './compare/compare';

import React, { useState, useEffect, useMemo } from 'react';
import {BrowserRouter as Router, Route, Navigate,  Routes, useLocation, useRoutes} from "react-router-dom";

import PremiumRoutes from './PrivateRoutes/PremiumRoute';
import LoggedRoutes from './PrivateRoutes/LoggedRoute'
import Error from "./notFound/NotFound"
import CompanyStock from './company/CompanyStock';
import CompanyNews from './company/CompanyNews';
import DashboardLayout from './layouts';
import Calendar from './calendar/Calendar';

import Pricing from './account/pricing/Pricing';
import AccountHome from './account/accountHome/AccountHome';

import LoginScreen from "./account/LoginScreen";
import RegisterScreen from "./account/RegisterScreen";
import ForgotPasswordScreen from "./account/ForgotPasswordScreen";
import ResetPasswordScreen from "./account/ResetPasswordScreen";
import CompanyHome from './company/CompanyHome';
//import MainLayout from './mainLayout';



//import PrivateRoute from "./routing/PrivateRoute";




const App  = () =>  { 

  //const [validate, setValidate] = useState(false);



    
  var props = window.location.pathname
  function AddLogging({children}) {
  
    useEffect(() => {
  
    }, []);
  
    return <CompanyInfo {...children} {...props} hey = {window.location.pathname} />;
  }





  const routes = useRoutes([

    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="/companylist" replace />,
        },
        /*{
          path: 'company',
          element: <Navigate to="/companylist" replace />,
        },*/
        {
          path: 'companylist',
          element: <CompanyList />,
        },
    
        {
          path: 'company',
          element: <CompanyHome/>,
          children: [
            {
              path: ':id' ,
              element:  <AddLogging></AddLogging>
            },
            {
              element: <PremiumRoutes />,
              children: [          
                  {
                  path: ':id/stock' ,
                  element: <CompanyStock/> 
              },]
            },
            {
              element: <PremiumRoutes />,
              children: [          
                  {
                  path: ':id/news' ,
                  element: <CompanyNews/> 
              },]
            },
            {
              path: ':id/overview' ,
              element: <ComingSoon/>
            },
          ],
        },


        {
          path: 'faq' ,
          element:  <CompanyList/>
        },
        {
          path: 'disclaimer' ,
          element:  <DisclaimerPage/>
        },
        {
          path: 'privacy' ,
          element:  <Privacy/>
        },
        {
          path: 'terms&conditions' ,
          element:  <TermsAndConditions/>
        },
        {
          path: 'calendar' ,
          element:  <ComingSoon/>
        },
        {
          path: 'register' ,
          element:  <RegisterScreen/>
        },
        {
          path: 'login' ,
          element:  <LoginScreen/>
        },
        /*{
          path: 'passwordreset/:resetToken' ,
          element:  <ResetPasswordScreen/>
        },
        {
          path: 'forgotpassword' ,
          element:  <ForgotPasswordScreen/>
        },*/
        {
          path: 'forgotpassword' ,
          element:  <LoginScreen/>
        },
        {
          path: 'pricing' ,
          element:  <Pricing/>
        },
       // {
          //path: 'compare' ,
          //element:  <CompanyCompare/>
        //},
        {
          element: <PremiumRoutes />,
          children: [          
              {
            path: 'compare' ,
            element:  <Compare/>
          },]
        },
        {
          element: <PremiumRoutes />,
          children: [          
              {
            path: 'crypto' ,
            element:  <ComingSoon/>
          },]
        },
        {
          element: <LoggedRoutes />,
          children: [          
              {
            path: 'favorites' ,
            element:  <Favorite/>
          },]
        },

        {
          element: <PremiumRoutes />,
          children: [          
              {
            path: 'company/:id/stock' ,
            element:  <Calendar/>
          },]
        },
        {
          element: <PremiumRoutes />,
          children: [          
              {
            path: 'company/:id/overview' ,
            element:  <Calendar/>
          },]
        },
        {
          element: <LoggedRoutes />,
          children: [          
              {
            path: 'YourAccount' ,
            element:  <AccountHome/>
          },]
        },

        {
          path: '404' ,
          element:  <Error/>
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ],
    },



  ]);


/*        {
          path: 'company',
          element: <CompanyHome />,
          children: [
            {
              path: ':id' ,
              element:  <AddLogging></AddLogging>
            },

          ],
        },

        */

    return (
        //anything that goes in the div is a shared element
        <div>
          

          <AuthProvider>

              {routes}
  
              </AuthProvider>



          
        </div>
    );
}

export default App;



