import { Outlet, Navigate } from 'react-router-dom'
import React from 'react'
import AuthContext from '../context/AuthContext'
import { useEffect, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useAxios from '../api/useAxiosPrivate';
import PricingPopup from '../account/pricing/PricingPopUp';


const PremiumRoutes = () => {
    const axiosPrivate = useAxios();  
    let {user} = useContext(AuthContext)
    const [premStatus, setPremStatus] = useState(null);
    //let auth = {'token':false}
    //console.log(user)
    useEffect(() => {
        const fetchPrivateData = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
          try {
            const { data } = await axiosPrivate.get("/api/auth/authorize", config);   
            setPremStatus(data.status);
          } catch (error) {
            setPremStatus(false);
          }
        };
      
        fetchPrivateData();
      }, []); 
      if (premStatus === null) {
        return <div></div>; // You can replace this with a loading component
      }
    
      // After premStatus has a value, you can render the appropriate content
      return premStatus === true ? <Outlet /> : <Navigate to="/pricing" />;




      
    //return(
        //<Outlet/>
        //premStatus === true ? <Outlet/> : <Navigate to="/pricing"/>
        //user.premium == true ? <Outlet/> : <Navigate to="/pricing"/>
        //user != null ? <Outlet/> : <Navigate to="/pricing"/>

        
    //)
}

export default PremiumRoutes


