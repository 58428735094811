import React from 'react';
import  ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
//  import  {render} from 'react-dom';
import './index.css'
import App from './App';
import {BrowserRouter as Router, Route, Navigate,  Switch} from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import ScrollToTop from './utils/ScrollToTop';








const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(

    <Router>
        <AuthProvider>
          <ScrollToTop>
          <App />
          </ScrollToTop>
        </AuthProvider>
      </Router>
   
); 