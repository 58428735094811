import React ,{useEffect, useState} from 'react';
import { Container, Typography, Button, TextField, TableCell, TableContainer, Paper , Table, TableBody, TableRow, TableHead, AppBar, Toolbar, Grid, List, ListItem, CardMedia} from '@mui/material';
import {useMediaQuery} from '@mui/material';
import useAxios from '../api/useAxiosPrivate';
import { update } from 'lodash';
import { API } from '../api';
import Autocomplete from '@mui/material/Autocomplete';
import us_symbols from "../utils/us_symbols.json"
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import moment from 'moment';
import FavoriteSearchBar from '../utils/searchBars/FavoriteSearchBar';
import PricingPopup from '../account/pricing/PricingPopUp';



const Favorite = () => {
 
    const navigate = useNavigate(); // Use useNavigate hook
    const isMiniScreen = useMediaQuery('(max-width: 800px)');
    const [text, setText] = useState('');
    const [premStatus, setPremStatus] = useState(null);
    const axiosPrivate = useAxios();
    const [isLoading, setIsLoading] = useState(true);
    //const favorite = "aapl"
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const isSmallScreenLabel = useMediaQuery('(max-width:600px)');
    const [count, setCount] = useState(0);
    const [favList, setFavList] = useState([]);
    const [frequency, setFrequency] = useState("list"); 
    const [updateList, setUpdateList] = useState("");
    const [companyData, setCompanyData] = useState([]);
    const [news, setNews] = useState([]);
    const [error, setError] = useState("");
    const [visibleNewsCount, setVisibleNewsCount] = useState(30); // Number of initially visible news articles
    const [isPricingOpen, setIsPricingOpen] = useState(false);

    const handleOpenPricing = () => {
      setIsPricingOpen(true);
    };
  
    const handleClosePricing = () => {
      setIsPricingOpen(false);
    };


    const handleFrequency = (value) => {
      if(value === "news" && (premStatus === false)){
        handleOpenPricing()
        return
      }
      if(value === "news" && (premStatus === null)){
        return
      }
      // Remove the selected company from the companies array
      setFrequency(value)
    };


    const formatDate = (rawDate) => {
      const dateObject = moment(rawDate);
      const formattedDate = dateObject.format('MMM D, YYYY');
      return formattedDate;
    };
  


    const abbreviateNumber = (number) => {
        if (number >= 1e12) {
          return (number / 1e12).toFixed(2) + "T";
        } else if (number >= 1e9) {
          return (number / 1e9).toFixed(2) + "B";
        } else if (number >= 1e6) {
          return (number / 1e6).toFixed(2) + "M";
        } else {
          return number.toFixed(2);
        }
      };


      useEffect(() => {
        const fetchPrivateData = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
          try {
            const { data } = await axiosPrivate.get("/api/auth/authorize", config);   
            //console.log(data);
            setPremStatus(data.status);
            //console.log(data.status); // Log the updated premStatus here
          } catch (error) {
            setPremStatus(false);
          }
        };
      
        fetchPrivateData();
      }, []); 





    useEffect(() => {
        const  quoteGetter = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
  
          try {
  
            const fetchRequests = favList.map((id) =>
            API.post("/api/data/quote", { id }, config, { withCredentials: true })
          );
  
          const responses = await Promise.all(fetchRequests);
          const listData = responses.map((response) => response.data);
          const flattenedListData = listData.flat();
            setCompanyData(flattenedListData)
            //console.log(flattenedListData)
  
          setIsLoading(false);
      
          } catch (error) {
            setIsLoading(false);
            console.log(error)
            setError(error);
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        };
        quoteGetter()
  
  
     
        }, [favList]); 




        useEffect(() => {
          console.log(frequency)
          if(frequency === "news"){
            const fetchNews = async () => {
              try {
                const config = {
                  headers: {
                    "Content-Type": "application/json",
                  },
                };
                var ids = favList
                const [quoteResponse] = await Promise.all([
                  API.post("/api/data/multnews", { ids }, config, { withCredentials: true }),
        
                ]);
          
                const quote = quoteResponse.data;
                setNews(quote)
                console.log(quote)
        
              } catch (error) {
                setError(error);
              }
            };
          
            fetchNews();
          }

        }, [frequency]);






        const toggleFavorite = async (favorite, addOnly) => {
          setText("")
            if(favorite === ""){
                return
            }
            favorite = favorite.toUpperCase()
            let addOrToggle = ""
            if(addOnly === true){
                addOrToggle = "addfav"
            }
            else{
                addOrToggle ="favorite"
            }
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
          let id = favorite

          try {
            const {data} = await API.post("/api/data/quote", { id }, config, { withCredentials: true })
            //console.log(data)
            if(data.length === 0){
            }
            else{
                await axiosPrivate.post(`/api/data/${addOrToggle}`, {favorite}, config, { withCredentials: true });  
                setCount(count + 1); 
            }

            
          } catch (error) {

            console.log(error)
            setText("")
          }
        };
      

      

      useEffect(() => {
        const fetchPrivateData = async () => {
          const config = {
            header: {
              "Content-Type": "application/json",
            },
          };
          try {
            const { data } = await axiosPrivate.get("/api/data/favlist", config, { withCredentials: true });   
            //console.log(data);
            setFavList(data);
            
            //console.log(data.status); // Log the updated premStatus here
          } catch (error) {
            setFavList("error");
            console.log(error)
          }
        };
      
        fetchPrivateData();
      }, [count]); 

      const handleLoadMore = () => {
        // Increase the visible news count by a certain number (e.g., 5) when the "Load More" button is clicked
        //if(premStatus === null || )
        setVisibleNewsCount(visibleNewsCount + 10);
      };

      const styles = {
        media: {
          height: 170,
          //paddingTop: '56.25%', // 16:9,
          marginTop:'30'
        }
    };

      //console.log(favList)



      useEffect(() => {
        // Add a click event listener to the clear button
        const close = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (close) {
          close.addEventListener('click', () => {
            // Handle clearing the input, e.g., setText('')
            setText('');
          });
        }
      }, []);

      const newSymbolsWithStyling = us_symbols.map((item) => ({
        ...item,
        labelWithGreyLastWord: (
          <div>
            <span style={{  fontSize: isSmallScreenLabel ? '11px' : '16px' }}>
              {item.label}
            </span>{' '}
            <span style={{ color: 'grey', fontSize: isSmallScreenLabel ? '10px' : '12px' }}>{item.symbol}</span>
          </div>
        ),
      }));




  return (
    <>
     <br></br> <br></br>
    <Typography
      sx={{
        fontFamily: 'Roboto Mono',
        fontWeight: 'bold',
        fontSize: isSmallScreen ? '20px' : '30px',
        textAlign: 'center', // Center text horizontally
      }}
    >
      Favorites
    </Typography>
    <br></br> <br></br>
   
    <AppBar elevation={0} style={{ height: '80px', background: '#F9F9F9' }} position="static">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="center">
            <Typography onClick={() => handleFrequency("list")} style={{fontWeight:"bold",display: 'inline-block',padding: '8px 16px',backgroundColor: '#E5E5E5',cursor: 'pointer',textDecoration: 'none',color: frequency === 'list' ? 'black' : '#A0A0A0',textDecoration: frequency === 'quarterly' ? 'underline' : 'none',fontFamily: 'Roboto Mono',}}>List</Typography>
            <Typography onClick={() => handleFrequency("news")} style={{fontWeight:"bold",display: 'inline-block',padding: '8px 16px',backgroundColor: '#E5E5E5',cursor: 'pointer',textDecoration: 'none',color: frequency === 'news' ? 'black' : '#A0A0A0',textDecoration: frequency === 'annual' ? 'underline' : 'none',fontFamily: 'Roboto Mono',}}>News</Typography>
          </Grid>
          </Toolbar>
    </AppBar>

      <br></br>




      {frequency === "list" ? (
    <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '25x' }}>

  <Autocomplete
            //disablePortal
            freeSolo                  // TJIS DISABLES MUST BE A VALUE WARNING
            options={text === "" ? [] : newSymbolsWithStyling}
              value={text}
              noOptionsText={<span style={{   fontSize: isSmallScreenLabel ? '11px' : '16px'  }}>Start Searching </span>}
              sx={{ width: '300px', mx: 'auto' }} // Center horizontally
              filterOptions={(options, { inputValue }) => {
              let count = 0;
              const filteredOptions = options.filter((option) => {
              const matches =
                option.label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
                option.symbol.toLowerCase().startsWith(inputValue.toLowerCase());
          
              // If the option matches the filter criteria and the count is less than 50, include it
              if (matches && count < 100) {
                count++;
                return true;
              }
              return false;
            });
            // Sort the filtered options by their index in the original data source
            return filteredOptions.sort((a, b) => {
              const indexA = newSymbolsWithStyling.findIndex(
                (item) => item.label === a.label && item.symbol === a.symbol
              );
              const indexB = newSymbolsWithStyling.findIndex(
                (item) => item.label === b.label && item.symbol === b.symbol
              );
              return indexA - indexB;
            });
          }}
          onBlur={() => {
            if (text !== '') {
              // Clear the text when the user clicks off the text field
              setText('');
            }
          }}
          
          renderOption={(props, option) => (

            <li
            {...props}
            onClick={() => toggleFavorite(option.symbol, true)} // Call handleSearch when the option is clicked
          >
            {option.labelWithGreyLastWord}
          </li>

        )}

        renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Roboto Mono', // Change font for the label
                fontWeight: 'bold', // Make the label bold
                fontSize: isSmallScreenLabel ? '14px' : '16px', // Adjust font size based on screen size\
              }}
            >
              Add Ticker...
            </Typography>
          }
          //autoFocus
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent form submission
              toggleFavorite(text, true);
            }
          }}
          autoComplete="off"
          size="small"
          sx={{
            // Styling for the TextField
            "& fieldset": { border: 'none'  },
            background: '#E5E5E5',
            fontFamily: 'Roboto Mono',
            '& .MuiOutlinedInput-root': {
              borderBottom: '5px solid black',
              boxShadow: 'none',
            },
          }}
          
        />
      )}
      PaperComponent={({ children }) => (
        <Paper sx={{ fontFamily: 'Roboto Mono', color: 'black', }}>{children}</Paper>
      )}
    />

  <br></br>  <br></br>  <br></br>  




    {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper}   style={{
                fontFamily: 'Roboto Mono',
                backgroundColor: '#F9F9F9',
                border: 'none',
                boxShadow: 'none', // Remove the box shadow
                marginRight: '100px',
              }}>
                <Table aria-label="company data table" style={{ fontFamily: 'Roboto Mono' }}>
                  <TableHead >
                    <TableRow>
                      <TableCell style={{ fontFamily: 'Roboto Mono' }}>Company</TableCell>
                      {!isSmallScreen ? (
                        <>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Price</TableCell>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Change</TableCell>
                      <TableCell align="right" style={{ fontFamily: 'Roboto Mono' }}>Market Cap</TableCell>
                      </>
                      ) : (
                        <></>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyData.map((data) => (
                      <TableRow key={data.symbol}>
                        <TableCell component="th" scope="row" style={{ fontFamily: 'Roboto Mono',  }}>
                          <Button component={Link} to={`/company/${data.symbol}`} style={{ color: 'black', textDecoration: 'none', fontSize: '14px', fontWeight: 'bold' }}>
                            {data.name}
                            </Button>
                            <span style={{ color: 'grey', fontSize: '12px'  }}>{data.symbol}</span>
                            {favList.includes(data.symbol) ? (
                            <GradeIcon  onClick={() => toggleFavorite(data.symbol)} style={{ cursor: 'pointer', color: 'red', fontSize: '20px', marginLeft: '20px',verticalAlign: 'sub' }} />
                            ) : (
                                <StarOutlineIcon  onClick={() => toggleFavorite(data.symbol)}style={{ cursor: 'pointer',  color: 'red', fontSize: '20px', marginLeft: '20px',verticalAlign: 'sub' }} />
                        )}
                         </TableCell>
                        {!isMiniScreen ? (
                          <>
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Roboto Mono', fontWeight: 'bold',
                          }}
                        >
                          {data.price} {/* Replace 'price' with the actual key for stock price in your data */}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: data.change < 0 ? 'red' : 'green',
                            fontFamily: 'Roboto Mono',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.change >= 0 ? `+${data.change.toFixed(2)}%` : `${data.change.toFixed(2)}%`}
                        </TableCell>
                        <TableCell align="right" style={{ fontFamily: 'Roboto Mono', fontWeight: 'bold',}}>
                          {abbreviateNumber(data.marketCap)}
                        </TableCell>
                        </>
                        ) : (
                         <></>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

    </Container>
  ) : frequency === "news" && premStatus === true ? (
    <>

<Container style={{ textAlign: 'center', maxWidth: '90%' }} justifycontent='space-between' alignitems='stretch' spacing={2} >

<Typography sx={{fontFamily: 'Roboto Mono',fontStyle: 'italic', fontSize: isSmallScreen ? '20px' : '20px', }} >  News For Favorited Companies </Typography>
  <br></br><br></br><br></br>      
  <List>
    {news.slice(0, visibleNewsCount).map((article, index) => {
      // Truncate the article.text to 200 characters
      var articleLength = isSmallScreen ? 100 : 240;
      const truncatedText =
        article.text.length > articleLength
          ? article.text.slice(0, articleLength) + '...'
          : article.text;

    return (
      <Link
        to={article.url}
        key={index}
        style={{ textDecoration: 'none', marginBottom: '10px' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItem
          style={{
            border: '1px solid #ccc',
            borderRight: 'none',
            borderLeft: 'none',
            borderTop: 'none',
            height: '200px',
            marginBottom: '10px',
          }}
        >
          <Grid container spacing={2}>
          {isSmallScreen ? null : (
            <Grid item xs={3}>
              <CardMedia
                component="img"
                image={article.image}
                style={styles.media} 
              />
            </Grid>
            )}
            <Grid item xs={isSmallScreen ? 9 : 7}>
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'black', fontWeight: 'bold', fontSize: isSmallScreen ? "14px" : "20px", }}>
                {article.title}
              </Typography>
              <br />
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'grey', fontSize: isSmallScreen ? "12px" : "13px", }}>
                {truncatedText}
              </Typography>
            </Grid>
            <Grid item xs={isSmallScreen ? 3 : 2}>
              <Typography style={{ fontFamily: 'Roboto Mono', color: 'black', marginBottom: '80px', fontSize: isSmallScreen ? "10px" : "13px", fontWeight:"bold" }}>
                {article.symbol} * {formatDate(article.publishedDate)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Link>
    );
  })}
</List>
    
    {visibleNewsCount < news.length && (
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        )}
  </Container>



    </>
    ) : handleOpenPricing()}
    


    <PricingPopup isOpen={isPricingOpen} onClose={handleClosePricing} />
    </>
  );
};

export default Favorite;