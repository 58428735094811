import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const DisclaimerPage = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '20px', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Disclaimer</Typography>
      
      <Typography variant="body1">
        **Disclaimer Policy for ChartingFS**

        **Effective Date: 2023-08-30**

        **1. Introduction**

        Welcome to CharingFS (the "Service"). This Disclaimer Policy is designed to inform users ("User," "You," or "Your") about the limitations, responsibilities, and disclaimers related to the use of our Service.

        By accessing or using our Service, You agree to the terms and conditions outlined in this Disclaimer Policy. If You do not agree with these terms, please refrain from using the Service.

        **2. Financial Data Disclaimer**

        The financial data, charts, and information provided by ChartingFS are for informational purposes only and should not be considered financial or investment advice. We do not provide recommendations or endorsements of specific financial products or investments. Users are solely responsible for their financial decisions and should seek professional financial advice as needed.

        **3. Accuracy and Reliability**

        While we make every effort to ensure the accuracy and reliability of the data and charts presented on our Service, we do not guarantee the correctness, completeness, or timeliness of the information. Data may be subject to delays, inaccuracies, or errors, and we do not accept liability for any losses or damages incurred due to reliance on such information.

        **4. Third-Party Data**

        ChartingFS may use third-party data providers to source financial information. We do not control the accuracy or availability of data from these providers. Users should refer to the terms and conditions of these third-party providers for additional information.

        **5. Risk Disclosure**

        Investing in financial markets involves inherent risks. Past performance is not indicative of future results. Users are advised to conduct their research, perform due diligence, and consider their risk tolerance before making investment decisions.

        **6. Availability and Technical Issues**

        ChartingFS strives to provide uninterrupted access to our Service. However, we do not guarantee that the Service will be available at all times or free from technical issues, disruptions, or downtime. We are not liable for any losses or damages resulting from the unavailability or interruptions of our Service.

        **7. No Warranty**

        We provide the Service "as is" and "as available" without warranties of any kind, whether express or implied. We disclaim all warranties, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.

        **8. Limitation of Liability**

        To the extent permitted by law, ChartingFS and its affiliates, directors, officers, employees, or agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or business opportunities, arising from the use of or inability to use the Service.

        **9. Changes to Disclaimer Policy**

        We reserve the right to update, modify, or change this Disclaimer Policy at any time without notice. Users are encouraged to review this policy periodically for any changes. Your continued use of the Service after changes to this policy will constitute acceptance of the revised terms.

        **10. Contact Information**

        If you have any questions or concerns regarding this Disclaimer Policy, please contact us at chartingfs@gmail.com.
      </Typography>
    </Container>
  );
};

export default DisclaimerPage;